import React from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { makeStyles, withStyles } from "@material-ui/core/styles";


const CssTextField = withStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color: theme.palette.text.secondary,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.text.secondary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.text.secondary,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.text.secondary,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.text.secondary,
      },
    },
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage:
      "url(https://images.unsplash.com/photo-1530274083826-2facadf42457?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1268&q=80%27)",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.background.default,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 1),
    backgroundColor: "#dcd8d8",
  },
  link: {
    color: theme.palette.text.secondary,
  },
}));

export default () => {
  const classes = useStyles();
  const { id, token } = useParams();
  const [reset, setReset] = React.useState(false);
  const [formData, setFormData] = React.useState({
    uid: id,
    token: token,
    new_password1: "",
    new_password2: "",
  });
  const [errors, setErrors] = React.useState({});

  const handleChange = (event) => {
    if (event.target.name === "new_password1") {
      if ("password1" in errors) {
        setErrors({});
      }
    }
    if (event.target.name === "new_password2") {
      if ("password2" in errors) {
        setErrors({});
      }
    }
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    //Make a network call somewhere
    event.preventDefault();
    axios
      .post("/rest-auth/password/reset/confirm/", formData)
      .then((res) => {
        console.log(res.data);
        setErrors({});
        setReset(true);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        if (err && err.response && err.response.data) {
          setErrors(err.response.data);
        }
      });
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <VpnKeyIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset password
          </Typography>
          {"token" in errors | "uid" in errors ? (
            <Box mt={2} align="center">
              <Typography variant="caption" color="error">
                This reset link has expired.
              </Typography>
              <Typography variant="caption" color="error" paragraph>
                Please click "Forgot password" on the login page and follow the
                process from there.
              </Typography>
            </Box>
          ) : (
            "new_password2" in errors && (
              <Box mt={2} align="center">
                <Typography variant="caption" color="error">
                  {errors["new_password2"]}
                </Typography>
              </Box>
            )
          )}
          {reset ? (
            <Box align="center" mt={6}>
              <Typography variant="subtitle1">
                Password has been reset with the new password!
              </Typography>
              <Box mt={3}>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/login"
                >
                  Login
                </Button>
              </Box>
            </Box>
          ) : (
            <form className={classes.form} onSubmit={handleSubmit}>
              <CssTextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="new_password1"
                label="New password"
                name="new_password1"
                type="password"
                autoFocus
                onChange={handleChange}
              />

              <CssTextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="new_password2"
                label="Repeat new password"
                name="new_password2"
                type="password"
                onChange={handleChange}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
              >
                Submit
              </Button>
            </form>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
