import React from "react";
import { UserContext } from "contexts/UserContext";
import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import BibleReferenceAutocomplete from "./BibleReferenceAutocomplete";

import songApi from "restApi/songsApi";

const referenceObjectToString = (referenceObject) =>
  referenceObject &&
  `${referenceObject.book} ${referenceObject.chapter}:${referenceObject.verse}`;

export default ({ song, verses, setVerses }) => {
  const userContext = React.useContext(UserContext);
  const canEdit = !!(
    userContext.user.is_team_leader | userContext.user.is_staff
  );
  const handleAdd = (verse) => {
    if (
      verses.findIndex(
        (i) =>
          i.book === verse.book &&
          i.chapter === verse.chapter &&
          i.verse === verse.verse
      ) > -1
    ) {
      return false;
    }
    songApi
      .patch(song.url, {
        bible_references: verses.concat(verse),
      })
      .then((res) => {
        setVerses(verses.concat([verse]));
      })
      .catch((err) => {
        return false;
      });
    return true;
  };

  const handleDelete = (verse) => {
    const reducedVerses = verses.filter(
      (i) =>
        i.book !== verse.book ||
        i.chapter !== verse.chapter ||
        i.verse !== verse.verse
    );
    if (reducedVerses.length < verses.length) {
      songApi
        .patch(song.url, {
          bible_references: reducedVerses,
        })
        .then((res) => {
          setVerses(reducedVerses);
        });
      // catch?
    }
  };

  const handleOpenBibleReference = (verse) => {
    const verseLink = `https://www.bible.com/bible/111/${verse.shorthand}.${verse.chapter}.${verse.verse}.NIV`;
    window.open(verseLink, "_blank");
  };
  const list = verses.map((verse, index) => (
    <ListItem key={index}>
      <Chip
        label={referenceObjectToString(verse)}
        onDelete={canEdit ? () => handleDelete(verse) : null}
        onClick={() => {
          handleOpenBibleReference(verse);
        }}
      />
    </ListItem>
  ));

  return (
    <>
      {list.length > 0 ? (
        <>
          <Box pt={2} px={2}>
            <Typography variant="subtitle1">Bible referenses</Typography>
            <Divider />
          </Box>
          <List>{list}</List>
        </>
      ) : (
        <Box p={2} pb={0} style={{ maxWidth: 250 }}>
          <Typography variant="subtitle1" color="textSecondary">
            No bible verses are assosiated with this song
          </Typography>
        </Box>
      )}
      {canEdit && <BibleReferenceAutocomplete onSubmit={handleAdd} />}
    </>
  );
};
