import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import songsAPI from "../../../restApi/songsApi";

export default ({ open, handleClose, addSong }) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [data, setData] = React.useState({
    title: "",
    artist: "",
    youtube_link: "",
    spotify_link: "",
    lyrics: "",
  });
  const [search, setSearch] = React.useState({
    inProgress: false,
    status: null,
  });
  const [errors] = React.useState({});
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    songsAPI.createSong(data)
        .then((res) => {
        addSong(res.data);
        setData({
          title: "",
          artist: "",
          youtube_link: "",
          spotify_link: "",
          lyrics: "",
        });
        handleClose();
      })
  };

  const errorMessages = Object.entries(errors).map((error, index) => {
    let text = error[1][0];

    if (
      text === "The fields title, artist, proposed_for must make a unique set."
    ) {
      text = "This song already exist";
    } else if (error[0] === "unique_constraint") {
      text = "This song already exist";
    }
    return (
      <Typography variant="caption" color="error" key={"error" + index}>
        {text}
      </Typography>
    );
  });

  const searchForLyrics = () => {
    setSearch({ inProgress: true, status: null });
    songsAPI.createLyrics(data)
        .then((res) => {
        setData({ ...data, lyrics: res.data });
        setSearch({ inProgress: false, status: 200 });
      },() => setSearch({ inProgress: false, status: 404 }));
  };

  const handleSearchTooltipClose = () => {
    setSearch({ inProgress: false, status: null });
  };

  const searchable =
    (data.title !== null) & (data.artist !== null)
      ? (data.title.length > 2) & (data.artist.length > 0) & !search.inProgress
      : false;

  const textFieldStyle = { marginBottom: "12px" };
  const searchButtonStyle = {
    position: "absolute",
    top: "8px",
    right: "8px",
    zIndex: 3,
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="creation-dialog-title"
      maxWidth="xs"
      fullScreen={smallScreen}
    >
      <DialogTitle id="creation-dialog-title">
        Create a new proposal
      </DialogTitle>
      <Box textAlign="center">{errorMessages}</Box>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <TextField
            id="title"
            name="title"
            label="Title"
            type="text"
            color="secondary"
            style={textFieldStyle}
            fullWidth
            onChange={handleChange}
          />
          <TextField
            id="artist"
            name="artist"
            label="Artist"
            type="text"
            color="secondary"
            style={textFieldStyle}
            fullWidth
            onChange={handleChange}
          />
          <TextField
            id="youtube_link"
            name="youtube_link"
            label="YouTube link"
            type="url"
            color="secondary"
            style={textFieldStyle}
            fullWidth
            onChange={handleChange}
          />
          <TextField
            id="spotify_link"
            name="spotify_id"
            label="Spotify link"
            type="url"
            color="secondary"
            style={textFieldStyle}
            fullWidth
            onChange={handleChange}
          />
          <Box mt={2} style={{ position: "relative" }}>
            <Tooltip
              open={search.status === 404}
              onClose={handleSearchTooltipClose}
              leaveDelay={2000}
              title="Could not find lyrics. Try again?"
              placement="left"
            >
              <Fab
                style={searchButtonStyle}
                size="small"
                disableFocusRipple={true}
                disabled={!searchable}
                onClick={searchForLyrics}
              >
                {search.inProgress ? (
                  <CircularProgress color="secondary" size={25} />
                ) : (
                  <SearchIcon />
                )}
              </Fab>
            </Tooltip>
            <TextField
              id="lyrics"
              name="lyrics"
              label="Lyrics"
              type="textArea"
              color="secondary"
              variant="filled"
              style={textFieldStyle}
              rows={8}
              rowsMax={8}
              multiline
              fullWidth
              value={data.lyrics}
              onChange={handleChange}
            />
          </Box>
        </DialogContent>
        <DialogActions style={{ marginTop: "8px" }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
