import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { UserContext } from "../../contexts/UserContext";
import type { AppConfig } from "../../models/config"
import GoogleLoginButton from 'react-social-login-buttons/lib/buttons/GoogleLoginButton';
import FacebookLoginButton from 'react-social-login-buttons/lib/buttons/FacebookLoginButton';
import MicrosoftLoginButton from 'react-social-login-buttons/lib/buttons/MicrosoftLoginButton';
import { LoginSocialGoogle, LoginSocialFacebook, LoginSocialMicrosoft } from 'reactjs-social-login';

const useStyles = makeStyles((theme) => ({
  facebookButton: {
    width: "100%",
    lineHeight: 1.75,
    padding: "6px 16px",
    border: "None",
    borderRadius: "4px",
    backgroundColor: "#4c69ba",
    color: "#fff",
    margin: theme.spacing(1, 0, 3),
  },
  socialButton: {
    marginBottom: "20px"
  }
}));


type Props = {
  config: AppConfig
}

export const Signup = (props: Props) => {
  const { config } = props;
  const facebookAppId = config ? config.socialApps.facebook : null;
  const googleAppId = config ? config.socialApps.google : null;
  const microsoftAppId = config ? config.socialApps.microsoft : null;
  const invitationCode = new URLSearchParams(useLocation().search).get(
    "invitation"
  );
  const [state, setState] = React.useState({
    loading: true,
    team: {},
    error: "",
  });
  const [validationErrors, setValidationErrors] = React.useState(null);
  const [fData, setFData] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    password1: "",
    password2: "",
  });
  const [login, setLogin] = React.useState(false);
  const userContext = React.useContext(UserContext);
  const classes = useStyles();

  React.useEffect(() => {
    axios.get("/api/team/invitation/" + invitationCode)
      .then((res) => {
        setState({
          loading: false,
          team: res.data,
          error: "",
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setState({
            team: {},
            loading: false,
            error: err.response.data,
          });
        } else {
          setState({
            team: {},
            loading: false,
            error: "",
          });
        }
      });
  }, []);

  const socialSignup = (res, provider, accessToken) => {
    if (accessToken) {
      axios
        .post(`/rest-auth/${provider}/signup/`, {
          access_token: accessToken,
          team: state.team.id,
        })
        .then((res) => {
          const user = {
            email: res.data.user.email,
            first_name: res.data.user.first_name,
            team: res.data.user.team,
            is_team_leader: res.data.user.is_team_leader,
            is_staff: res.data.user.is_staff,
            isAuthenticated: true,
          };
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(user));
          userContext.setUser(user);
          axios.defaults.headers.common[
            "Authorization"
          ] = `JWT ${localStorage.getItem("token")}`;
          setLogin(true);
        })
        .catch((err) => console.log("error"));
    }
  }

  const responseFacebook = (res) => {
    if (res && res.accessToken) {
      socialSignup(res, 'facebook', res.accessToken);
    }
  };

  const responseGoogle = (res) => {
    if (res && res.access_token) {
      socialSignup(res, 'google', res.access_token);
    }
  };

  const responseMicrosoft = (res) => {
    if (res && res.access_token) {
      socialSignup(res, 'microsoft', res.access_token);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    data.append("team", state.team.id);
    axios
      .post("/rest-auth/registration/", data)
      .then((res) => {
        const user = { ...res.data.user, isAuthenticated: true };
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(user));
        userContext.setUser(user);
        setLogin(true);
      })
      .catch((err) => {
        if (err.response && err.response.status === 400) {
          setValidationErrors(err.response.data);
        }
      });
  };

  const handleFormChange = (event) => {
    setFData({ ...fData, [event.target.name]: event.target.value });
  };

  if (login) {
    return <Redirect to="/calendar" />;
  }

  return (
    <Container maxWidth="sm">
      <Paper>
        <Box p={2} textAlign="center">
          {state.loading ? (
            ""
          ) : state.error.length > 0 ? (
            <Typography variant="body2">{state.error}</Typography>
          ) : (
            state.team !== null && (
              <Box py={4}>
                <Box mb={6}>
                  <Typography variant="h6">{`Join ${state.team.name}, ${state.team.city}'s worship team!`}</Typography>
                </Box>
                <Container maxWidth="xs">
                  {facebookAppId && <LoginSocialFacebook
                      className={classes.socialButton}
                      appId={facebookAppId}
                      onResolve={({ provider, data }) => responseFacebook(data)}
                      onReject={err => console.log(err)}>
                      <FacebookLoginButton text={"SIGN UP WITH FACEBOOK"}/>
                    </LoginSocialFacebook>}

                  {googleAppId && <LoginSocialGoogle
                      className={classes.socialButton}
                      client_id={googleAppId}
                      scope="openid profile email"
                      discoveryDocs="claims_supported"
                      access_type="offline"
                      onResolve={({ provider, data }) => responseGoogle(data)}
                      onReject={err => console.log(err)}>
                    <GoogleLoginButton text={"SIGN UP WITH GOOGLE"}/>
                  </LoginSocialGoogle>}

                  {microsoftAppId && <LoginSocialMicrosoft
                      className={classes.socialButton}
                      redirect_uri={window.location.origin + window.location.pathname}
                      scope={'profile openid email User.Read'}
                      client_id={microsoftAppId}
                      onResolve={({ provider, data }) => responseMicrosoft(data)}
                      onReject={err => console.log(err)}>
                    <MicrosoftLoginButton text={"SIGN UP WITH MICROSOFT"}/>
                  </LoginSocialMicrosoft>}

                  <hr/>
                  <span>OR</span>
                  <hr/>

                  {validationErrors !== null &&
                    "non_field_errors" in validationErrors && (
                      <Box pb={2}>
                        <Typography color="error" variant="caption">
                          {validationErrors.non_field_errors}
                        </Typography>
                      </Box>
                    )}
                  <form onSubmit={handleSubmit}>
                    <TextField
                      type="text"
                      label="First name"
                      fullWidth
                      name="first_name"
                      variant="filled"
                      color="secondary"
                      value={fData.first_name}
                      onChange={handleFormChange}
                      helperText={
                        validationErrors !== null &&
                        "first_name" in validationErrors &&
                        validationErrors.first_name
                      }
                      error={
                        validationErrors !== null &&
                        "first_name" in validationErrors
                      }
                    />
                    <Box mt={2}>
                      <TextField
                        type="text"
                        label="Last name"
                        fullWidth
                        name="last_name"
                        variant="filled"
                        color="secondary"
                        value={fData.last_name}
                        onChange={handleFormChange}
                        helperText={
                          validationErrors !== null &&
                          "last_name" in validationErrors &&
                          validationErrors.first_name
                        }
                        error={
                          validationErrors !== null &&
                          "last_name" in validationErrors
                        }
                      />
                    </Box>
                    <Box mt={2}>
                      <TextField
                        type="email"
                        label="Email"
                        fullWidth
                        name="email"
                        variant="filled"
                        color="secondary"
                        value={fData.email}
                        onChange={handleFormChange}
                        helperText={
                          validationErrors !== null &&
                          "email" in validationErrors &&
                          validationErrors.email
                        }
                        error={
                          validationErrors !== null &&
                          "email" in validationErrors
                        }
                      />
                    </Box>
                    <Box mt={2}>
                      <TextField
                        type="password"
                        label="Password"
                        fullWidth
                        name="password1"
                        variant="filled"
                        color="secondary"
                        value={fData.password1}
                        onChange={handleFormChange}
                        helperText={
                          validationErrors !== null &&
                          "password1" in validationErrors &&
                          validationErrors.password1
                        }
                        error={
                          validationErrors !== null &&
                          "password1" in validationErrors
                        }
                      />
                    </Box>
                    <Box mt={2}>
                      <TextField
                        type="password"
                        label="Verify password"
                        fullWidth
                        name="password2"
                        variant="filled"
                        color="secondary"
                        value={fData.password}
                        onChange={handleFormChange}
                        helperText={
                          validationErrors !== null &&
                          "password2" in validationErrors &&
                          validationErrors.password1
                        }
                        error={
                          validationErrors !== null &&
                          "password2" in validationErrors
                        }
                      />
                    </Box>
                    <Box mt={4} textAlign="right">
                      <Button type="sunmit" variant="contained" fullWidth>
                        Sign up
                      </Button>
                    </Box>
                  </form>
                </Container>
              </Box>
            )
          )}
        </Box>
      </Paper>
    </Container>
  );
};
