import React from "react";
import {
  Box,
  Container,
  CircularProgress,
  Fab,
  Paper,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddRounded";
import SongProposals from "./SongProposals";
import SongActionDialog from "./components/SongActionDialog";
import SongCreationDialog from "./components/SongCreationDialog";
import SongUpdateDialog from "./components/SongUpdateDialog";
import { Alert } from "react-bootstrap";
import songsAPI from "../../restApi/songsApi";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  addButtonLargeScreen: {
    position: "fixed",
    right: theme.spacing(3),
    bottom: theme.spacing(3),
    zIndex: 2,
  },
  addButtonSmallScreen: {
    position: "fixed",
    right: "50%",
    bottom: theme.spacing(2),
    transform: "translateX(50%)",
    zIndex: 2,
  },
}));

export default () => {
  const [state, setState] = React.useState({ loading: true, songs: [] });
  const [error] = React.useState("");
  const [songActionDialog, setSongActionDialog] = React.useState({
    song: null,
    open: false,
    action: null,
  });

  const [openCreationDialog, setOpenCreationDialog] = React.useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = React.useState({
    open: false,
    song: null,
  });

  const classes = useStyles();
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const handleOpenActionDialog = ({ song, action }) => {
    setSongActionDialog({ song: song, open: true, action: action });
  };

  const handleCloseDialog = (confirmedAction) => {
    if (confirmedAction) {
      // Send appropriate requests for the action
      if (songActionDialog.action === "add") {
        songsAPI
          .patch(songActionDialog.song.url, { known: true })
          .then((res) => {
            // Remove song from songs
            setState({
              loading: false,
              songs: state.songs.filter((s) => s.url !== res.data.url),
            });
          });
      }
      if (songActionDialog.action === "delete") {
        songsAPI.delete(songActionDialog.song.url).then((res) => {
          // Remove song from songs
          setState({
            loading: false,
            songs: state.songs.filter(
              (song) => song.url !== songActionDialog.song.url
            ),
          });
        });
      }
    }

    setSongActionDialog({ song: null, open: false, action: null });
  };

  const handleOpenCreationDialog = () => {
    setOpenCreationDialog(true);
  };

  const handleOpenUpdateDialog = (song) => {
    setOpenUpdateDialog({ open: true, song });
  };

  const handleCloseCreationDialog = () => {
    setOpenCreationDialog(false);
  };

  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog({ open: false, song: null });
  };

  const handleAddSong = (song) => {
    let s = state.songs;
    s.push(song);
    setState({ loading: false, songs: s });
  };

  const handleUpdateSong = (song) => {
    let s = state.songs;
    const songIndex = s.findIndex((si) => si.url === song.url);
    if (songIndex !== -1) {
      s[songIndex] = song;
    }
    setState({ loading: false, songs: s });
  };
  /* Fetch the songs from the API when component mounts */
  React.useEffect(() => {
    songsAPI.get("/api/songs/?known=").then((res) => {
      setState({
        songs: res.data,
        loading: false,
      });
    });
  }, []);

  return (
    <Container maxWidth="xl">
      {state.loading ? (
        <div
          style={{
            position: "absolute",
            top: "30%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : error ? (
        <Alert
          style={{ backgroundColor: "#424242", color: "rgb(191, 199, 199)" }}
        >
          Error: {error}
        </Alert>
      ) : state.songs.length ? (
        <Box pb={8}>
          <SongProposals
            songs={state.songs}
            handleOpenActionDialog={handleOpenActionDialog}
            handleOpenUpdateDialog={handleOpenUpdateDialog}
          />
        </Box>
      ) : (
        <Container maxWidth="sm">
          <Paper>
            <Box p={2}>
              <Typography variant="h5">
                Your team does not have any unapproved proposed songs!
              </Typography>
              <Box mt={2}>
                <Typography>
                  When Team leaders approve songs from the "Song Proposals"
                  page, they will be listed in the catalog.
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Container>
      )}
      {songActionDialog.open && (
        <SongActionDialog
          song={songActionDialog.song}
          open={songActionDialog.open}
          action={songActionDialog.action}
          handleClose={handleCloseDialog}
        />
      )}
      {openCreationDialog && (
        <SongCreationDialog
          open={openCreationDialog}
          handleClose={handleCloseCreationDialog}
          addSong={handleAddSong}
        />
      )}
      {openUpdateDialog.open && (
        <SongUpdateDialog
          open={openUpdateDialog.open}
          handleClose={handleCloseUpdateDialog}
          updateSong={handleUpdateSong}
          song={openUpdateDialog.song}
        />
      )}
      <Fab
        size="medium"
        variant="extended"
        className={
          largeScreen
            ? classes.addButtonLargeScreen
            : classes.addButtonSmallScreen
        }
        onClick={handleOpenCreationDialog}
      >
        <AddIcon />
        Add new
      </Fab>
    </Container>
  );
};
