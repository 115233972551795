import React from "react";
import { UserContext } from "../../../../contexts/UserContext";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import EventDisplayDialog from "./EventDisplayDialog";
import EventCreationDialog from "./EventCreationDialog";
import EventEditDialog from "./EventEditDialog";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.default,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  modalTeam: {
    display: "inline-block",
    border: "1px solid",
    padding: "3px 10px",
    borderRadius: "4px",
  },
  dialogTitle: {
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem",
    },
  },
  dialogContent: {
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem",
    },
    paddingBottom: "24px",
  },
  dialogTitleButton: {
    marginLeft: theme.spacing(1),
  },
}));

export default ({
  createEvent,
  updateEvent,
  deleteEvent,
  eventModal,
  setEventModal,
  closeEventModal,
  menuActions,
}) => {
  const [editMode, setEditMode] = React.useState(false);
  const userContext = React.useContext(UserContext);
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const toggleEditMode = () => setEditMode(!editMode);

  const handleClose = () => {
    setEditMode(false);
    closeEventModal();
  };

  return (
    <div>
      {eventModal.createMode ? (
        <EventCreationDialog
          fullScreen={fullScreen}
          classes={classes}
          createEvent={createEvent}
          eventModal={eventModal}
          setEventModal={setEventModal}
          closeEventModal={handleClose}
          userContext={userContext}
        />
      ) : editMode ? (
        <EventEditDialog
          fullScreen={fullScreen}
          classes={classes}
          updateEvent={updateEvent}
          deleteEvent={deleteEvent}
          eventModal={eventModal}
          setEventModal={setEventModal}
          closeEventModal={handleClose}
          toggleEditMode={toggleEditMode}
          userContext={userContext}
        />
      ) : (
        <EventDisplayDialog
          fullScreen={fullScreen}
          classes={classes}
          eventModal={eventModal}
          closeEventModal={closeEventModal}
          user={userContext.user}
          toggleEditMode={toggleEditMode}
          menuActions={menuActions}
        />
      )}
    </div>
  );
};
