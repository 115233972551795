import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SongCard from "./components/SongCard";
import { Fade, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default ({ songs, handleOpenActionDialog, handleOpenUpdateDialog }) => {
  const classes = useStyles();
  const songCards = songs.map((song, index) => (
    <SongCard
      song={song}
      handleOpenActionDialog={handleOpenActionDialog}
      handleOpenUpdateDialog={handleOpenUpdateDialog}
      key={index}
    />
  ));

  return (
    <Fade in={true} timeout={1000}>
      <div className={classes.root}>
        <Grid container spacing={3}>
          {songCards}
        </Grid>
      </div>
    </Fade>
  );
};
