import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import bibleVerseCount from "utils/bibleVerseCount.json";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minWidth: "250px",
  },
}));

const verseStartPattern = /([\d\s]*?[a-zA-Z]+)\s(\d+):(\d+)/;
const chapterPattern = /([\d\s]*?[a-zA-Z]+)\s(\d+):/;
const bookPattern = /([\d\s]*?[a-zA-Z]+)\s/;

String.prototype.capitalize = function () {
  return this.split(" ")
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join(" ");
};

export default ({ onSubmit }) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [selection, setSelection] = React.useState({
    book: null,
    chapter: null,
    verse: null,
  });

  const handleChange = (event, value, reason) => {
    if (reason === "select-option") {
      if (selection.book && selection.chapter) {
        setSelection({
          ...selection,
          verse: parseInt(value.split(":")[value.split(":").length - 1]),
        });
        const approved = onSubmit({
          ...selection,
          verse: parseInt(value.split(":")[value.split(":").length - 1]),
          shorthand: bibleVerseCount[selection.book].shorthand,
        });
        if (approved) {
          setSelection({ book: null, chapter: null, verse: null });
          setInputValue("");
        }
        // else?
      } else if (selection.book) {
        setSelection({
          ...selection,
          chapter: parseInt(value.split(" ")[value.split(" ").length - 1]),
        });
        setInputValue(
          `${selection.book} ${value.split(" ")[value.split(" ").length - 1]}:`
        );
      } else {
        setSelection({ ...selection, book: value });
        setInputValue(value + " ");
      }
    }
  };

  const handleInputChange = (event, value, reason) => {
    let inputValue = value.capitalize();
    if (reason === "reset") {
      if (!selection.book) {
        setInputValue(inputValue + " ");
      } else if (!selection.chapter) {
        setInputValue(inputValue + ":");
      } else {
        setInputValue(inputValue);
      }
    } else {
      setInputValue(inputValue);
    }
    updateStateFromInput(inputValue);
  };

  const updateStateFromInput = (value) => {
    const verseMatch = value.match(verseStartPattern);
    const chapterMatch = value.match(chapterPattern);
    const bookMatch = value.match(bookPattern);

    if (
      verseMatch &&
      verseMatch[1].capitalize() in bibleVerseCount &&
      verseMatch[2] in bibleVerseCount[verseMatch[1]] &&
      verseMatch[3] <= bibleVerseCount[verseMatch[1]][verseMatch[2]] &&
      verseMatch[3] > 0
    ) {
      setSelection({
        book: verseMatch[1].capitalize(),
        chapter: parseInt(verseMatch[2]),
        verse: parseInt(verseMatch[3]),
      });
    } else if (
      chapterMatch &&
      chapterMatch[1].capitalize() in bibleVerseCount &&
      chapterMatch[2] in bibleVerseCount[chapterMatch[1]]
    ) {
      setSelection({
        book: chapterMatch[1].capitalize(),
        chapter: parseInt(chapterMatch[2]),
        verse: null,
      });
    } else if (bookMatch && bookMatch[1].capitalize() in bibleVerseCount) {
      setSelection({
        book: bookMatch[1].capitalize(),
        chapter: null,
        verse: null,
      });
    } else {
      setSelection({ book: null, chapter: null, verse: null });
    }
  };

  const handleClose = (event, reason) => {
    if (selection.book && selection.chapter) {
      setOpen(false);
    }
  };

  const options = selection.book
    ? selection.chapter
      ? [
          ...Array(bibleVerseCount[selection.book][selection.chapter]).keys(),
        ].map((i) => `${selection.book} ${selection.chapter}:${i + 1}`)
      : Object.keys(bibleVerseCount[selection.book]).map(
          (chapter) => `${selection.book} ${chapter}`
        )
    : Object.keys(bibleVerseCount);

  const renderOptions = (option, state) => {
    return selection.book
      ? selection.chapter
        ? option.split(":")[option.split(":").length - 1]
        : option.split(" ")[option.split(" ").length - 1]
      : option;
  };

  return (
    <Box p={2} pt={0} className={classes.wrapper}>
      <Box>
        <Autocomplete
          id="bookReference"
          autoHighlight
          options={options}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={handleClose}
          onChange={handleChange}
          onInputChange={handleInputChange}
          popupIcon={false}
          clearOnBlur={false}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              color="secondary"
              label="Add new verse"
            />
          )}
          renderOption={renderOptions}
          value={null}
          inputValue={inputValue}
          size="small"
          onBlur={() => setOpen(false)}
        />
      </Box>
    </Box>
  );
};
