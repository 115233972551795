
// Since the api runs on the same port as the frontend, the ff should suffice. If things change however,
// the implementation would change...we will use host of the frontend to determine which api url to use.
const getBaseUrl = () => {
  const url = new URL(window.location);
  return url.origin;
};

module.exports = {
    TOKEN_KEY: 'token',
    USER_KEY: 'user',
    API_BASE_URL: getBaseUrl(),
}
