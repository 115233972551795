import axiosConfig from "./axiosConfig";

const eventsAPI = {};

eventsAPI.getUpcomingEvents = () =>
  axiosConfig.get(`/api/events/?upcoming=1&limit=100`);
eventsAPI.getEvents = () => axiosConfig.get(`/api/events/`);
eventsAPI.get = (id) => axiosConfig.get(`/api/events/${id}/`);
eventsAPI.assignSong = (data) => axiosConfig.post(`/api/assign-event/`, data);
eventsAPI.patchEvent = (eventId, data) =>
  axiosConfig.patch(`/api/events/${eventId}/`, data);
eventsAPI.createEvent = (data) => axiosConfig.post(`/api/events/`, data);
eventsAPI.deleteEvent = (eventId) =>
  axiosConfig.delete(`/api/events/${eventId}/`);

export default eventsAPI;
