import React from "react";
import axiosConfig from "restApi/axiosConfig";
import { UserContext } from "contexts/UserContext";
import Admin from "./Admin";
import { Box, CircularProgress, Container, Paper } from "@material-ui/core";
import { API_BASE_URL } from "CONSTANTS";

export default () => {
  const [data, setData] = React.useState({
    team: {},
    loading: true,
    errorMessage: "",
    snackbarOpen: false,
  });
  const userContext = React.useContext(UserContext);

  React.useEffect(() => {
    axiosConfig
      .get(`/api/teams/${userContext.user.team}/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setData({
          team: res.data,
          loading: false,
          errorMessage: "",
          snackbarOpen: false,
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 403) {
            setData({
              loading: false,
              errorMessage: "You do not have permission to view this page.",
              snackbarOpen: false,
            });
          } else {
            setData({
              loading: false,
              errorMessage: JSON.stringify(err.response.data),
              snackbarOpen: false,
            });
          }
        }
      });
  }, [userContext.user.team]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setData({ ...data, snackbarOpen: false });
  };

  const createInvitationCode = () => {
    axiosConfig
      .get("api/team/invitation/create", {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        navigator.clipboard.writeText(
          `${API_BASE_URL}/signup?invitation=${res.data}`
        );
        setData({
          ...data,
          team: { ...data.team, invitation_code: res.data },
          snackbarOpen: true,
        });
      })
      .catch((err) => console.log(err));
  };

  const connectToSpotify = () => {
    axiosConfig
      .get("/api/spotify/authorize", {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        window.location.href = res.data;
      })
      .catch((err) => console.log(err));
  };

  const disconnectSpotify = () => {
    axiosConfig
      .get("/api/spotify/deactivate", {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setData({ ...data, team: { ...data.team, spotify_username: false } });
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container maxWidth="lg">
      {data.loading ? (
        <Box
          style={{
            position: "absolute",
            top: "30%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : data.errorMessage ? (
        <Paper>
          <Box p={3} textAlign="center">
            {data.errorMessage}
          </Box>
        </Paper>
      ) : (
        <Admin
          team={data.team}
          createInvitationCode={createInvitationCode}
          connectToSpotify={connectToSpotify}
          disconnectSpotify={disconnectSpotify}
          snackbarOpen={data.snackbarOpen}
          handleSnackbarClose={handleSnackbarClose}
        />
      )}
    </Container>
  );
};
