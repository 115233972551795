import React from "react";
import moment from "moment";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Checkbox,
  ListItemText,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    minWidth: 120,
  },
  chips: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export default ({ song, open, handleClose, events }) => {
  const classes = useStyles();
  const initialSelect = events.filter(
    (event) => event.song_list.findIndex((songId) => songId === song.id) >= 0
  );
  const [selectedEvents, setSelectedEvents] = React.useState(initialSelect);

  React.useEffect(() => {
    setSelectedEvents(
      events.filter(
        (event) =>
          event.song_list.findIndex((songId) => songId === song.id) >= 0
      )
    );
  }, [song, events]);

  const handleChange = (event) => {
    setSelectedEvents(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleClose(selectedEvents);
  };

  return (
    <Dialog
      open={Boolean(open)}
      onClose={() => handleClose(null)}
      aria-labelledby="action-confirmation-text"
      aria-describedby="action-dialog-description"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="action-confirmation-text">
          {song.title}{" "}
          {song.artist && (
            <Typography variant="body1">{song.artist}</Typography>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="action-dialog-description">
            Please select upcoming event(s) that you want to assign this song
            to.
          </DialogContentText>
          <FormControl className={classes.formControl}>
            <InputLabel id="eventInput" color="secondary">
              Events
            </InputLabel>
            <Select
              labelId="eventInputLabel"
              id="eventMultipleChip"
              multiple
              color="secondary"
              value={selectedEvents}
              onChange={handleChange}
              input={<Input id="select-multiple-chip" />}
              MenuProps={{ variant: "menu" }}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value, index) => {
                    const timestamp = moment(value.time_start).format(
                      "YYYY/MM/DD"
                    );
                    const displayName = `${timestamp} - ${value.name}`;
                    return (
                      <Chip
                        key={index}
                        label={displayName}
                        className={classes.chip}
                      />
                    );
                  })}
                </div>
              )}
            >
              {events &&
                events.length > 0 &&
                events
                  .sort((a, b) => (a.time_start < b.time_start ? 1 : -1))
                  .map((e) => {
                    const timestamp = moment(e.time_start).format("YYYY/MM/DD");
                    const displayName = `${timestamp} - ${e.name}`;
                    return (
                      <MenuItem key={e.id} value={e}>
                        <Checkbox checked={selectedEvents.indexOf(e) > -1} />
                        <ListItemText primary={displayName} />
                      </MenuItem>
                    );
                  })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(null)} color="default">
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={
              JSON.stringify(
                selectedEvents.map((i) => i.id).sort((a, b) => b - a)
              ) ===
              JSON.stringify(
                initialSelect.map((i) => i.id).sort((a, b) => b - a)
              )
            }
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
