import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Container,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Calendar from "./Calendar";
import eventsAPI from "../../restApi/eventsApi";

const teamColors = [
  { light: "#FA8BB2", main: "#F50057", dark: "#5A0020" },
  { light: "#AED2F0", main: "#4D9DE0", dark: "#1C3A52" },
  { light: "#90E8BB", main: "#0CCE6B", dark: "#054B27" },
  { light: "#D5D1D7", main: "#A39BA8", dark: "#3C393E" },
  { light: "#EDFBCD", main: "#D8F793", dark: "#4F5A36" },
];

const formatEvents = (events) => {
  return events.map((event) => ({
    title: event.name,
    start: event.time_start,
    end: event.time_end,
    extendedProps: { team: event.team, id: event.id },
    borderColor: teamColors[event.team.id - 1].main,
    backgroundColor: "transparent",
  }));
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default () => {
  const now = new Date();
  const [loading, setLoading] = React.useState(true);
  const [events, setEvents] = React.useState([]);
  const [eventModal, setEventModal] = React.useState({
    id: null,
    loading: false,
    isOpen: false,
    createMode: false,
    team: null,
    title: null,
    details: "",
    timeStart: new Date(now.setUTCHours(12, 0, 0, 0)),
    timeEnd: new Date(now.setUTCHours(14, 0, 0, 0)),
    songs: [],
    spotify_playlist_id: "",
  });
  const history = useHistory();
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  let params = useQuery();

  /* Fetch the songs from the API when component mounts*/
  React.useEffect(() => {
    eventsAPI.getEvents().then((res) => {
      setEvents(formatEvents(res.data));
      setLoading(false);
    });
  }, []);

  /*eslint-disable */
  React.useEffect(() => {
    if (!loading) {
      if (events.length > 0) {
        const eventParam = params.get("event");
        if (eventParam && !eventModal.isOpen) {
          if (
            events.findIndex(
              (event) => event.extendedProps.id === parseInt(eventParam)
            ) > -1
          ) {
            getEventInfo(parseInt(eventParam));
          } else if (eventParam === "new") {
            openEventCreationModal();
          } else {
            params.delete("event");
            history.push({ search: params.toString() });
          }
        }
      }
    }
  }, [loading]);

  React.useEffect(() => {
    if (params.get("event") == null && eventModal.isOpen) {
      closeEventModal();
    }
  }, [params]);
  /*eslint-enable */

  const openEventCreationModal = () => {
    params.delete("event");
    params.append("event", "new");
    history.push({ search: params.toString() });
    setEventModal({
      ...eventModal,
      loading: false,
      team: null,
      title: null,
      details: "",
      songs: [],
      createMode: true,
      isOpen: true,
    });
  };
  const closeEventModal = () => {
    setEventModal({
      ...eventModal,
      createMode: false,
      isOpen: false,
      timeStart: new Date(now.setUTCHours(12, 0, 0, 0)),
      timeEnd: new Date(now.setUTCHours(14, 0, 0, 0)),
    });
    params.delete("event");
    history.push({ search: params.toString() });
  };

  const handleDateSelect = (info) => {
    setEventModal({
      ...eventModal,
      timeStart: new Date(info.start.setUTCHours(11, 0, 0, 0)),
      timeEnd: new Date(info.end.getTime() + (13 - 24) * 60 * 60 * 1000),
    });
  };

  const handleDateUnselect = () => {
    setEventModal({
      ...eventModal,
      timeStart: new Date(now.setUTCHours(12, 0, 0, 0)),
      timeEnd: new Date(now.setUTCHours(14, 0, 0, 0)),
    });
  };

  const getEventInfo = (eventId) => {
    const selectedEvent = events.find(
      (event) => event.extendedProps.id === eventId
    );
    setEventModal({
      loading: true,
      isOpen: true,
      id: eventId,
      team: {
        ...selectedEvent.extendedProps.team,
        color: teamColors[selectedEvent.extendedProps.team.id - 1].main,
      },
      title: selectedEvent.title,
      details: "",
      timeStart: selectedEvent.start,
      timeEnd: selectedEvent.end,
      songs: [],
      spotify_playlist_id: "",
    });

    eventsAPI.get(eventId).then((res) => {
      params.delete("event");
      params.append("event", eventId);
      history.push({ search: params.toString() });
      setEventModal({
        loading: false,
        isOpen: true,
        id: eventId,
        team: {
          ...selectedEvent.extendedProps.team,
          color: teamColors[selectedEvent.extendedProps.team.id - 1].main,
        },
        title: selectedEvent.title,
        details: res.data.details,
        timeStart: new Date(selectedEvent.start),
        timeEnd: new Date(selectedEvent.end),
        songs: res.data.song_list,
        spotify_playlist_id: res.data.spotify_playlist_id,
      });
    });
  };

  const handleUpdateSong = (song) => {
    let songs = eventModal.songs;
    songs[songs.findIndex((s) => (s.id = song.id))] = song;
    setEventModal({ ...eventModal, songs });
  };

  const handleUnassignEvent = (song, event) => {
    eventsAPI
      .patchEvent(event.id, {
        songs: eventModal.songs
          .filter((s) => s.id !== song.id)
          .map((s) => s.id),
      })
      .then(() => {
        setEventModal({
          ...eventModal,
          songs: eventModal.songs.filter((s) => s.id !== song.id),
        });
      });
  };

  const menuActions = {
    edit: handleUpdateSong,
    unassignEvent: handleUnassignEvent,
  };

  const createEvent = (content) => {
    setEvents(events.concat(formatEvents([content])));
  };

  const updateEvent = (modifiedContent) => {
    const event = {
      ...events.find((i) => i.extendedProps.id === modifiedContent.id),
      title: modifiedContent.title,
      start: modifiedContent.timeStart,
      end: modifiedContent.timeEnd,
    };

    let e = events.filter((e) => e.extendedProps.id !== event.extendedProps.id);
    e.push(event);
    setEvents(e);
  };

  const deleteEvent = (eventId) => {
    setEvents(events.filter((event) => event.extendedProps.id !== eventId));
  };

  return (
    <Container>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "30%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <Calendar
          events={events}
          createEvent={createEvent}
          updateEvent={updateEvent}
          deleteEvent={deleteEvent}
          eventModal={eventModal}
          setEventModal={setEventModal}
          openEventCreationModal={openEventCreationModal}
          closeEventModal={closeEventModal}
          getEventInfo={getEventInfo}
          handleDateSelect={handleDateSelect}
          handleDateUnselect={handleDateUnselect}
          menuActions={menuActions}
          largeScreen={largeScreen}
        />
      )}
    </Container>
  );
};
