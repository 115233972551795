import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Link,
  Grid,
  Typography,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { UserContext } from "contexts/UserContext";
import type { AppConfig } from "models/config"
import FacebookLoginButton from 'react-social-login-buttons/lib/buttons/FacebookLoginButton';
import GoogleLoginButton from 'react-social-login-buttons/lib/buttons/GoogleLoginButton';
import { LoginSocialFacebook, LoginSocialGoogle, LoginSocialMicrosoft } from 'reactjs-social-login';
import MicrosoftLoginButton from 'react-social-login-buttons/lib/buttons/MicrosoftLoginButton';

const PrivacyPolicy = () => (
  <Typography variant="body2">
    <Link
      color="textSecondary"
      href="https://drive.google.com/file/d/1hAXdj2bRjASzG-Bb26HQBywotV_QRW8_/view?usp=sharing"
    >
      Privacy Policy
    </Link>
  </Typography>
);

const CssTextField = withStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color: theme.palette.text.secondary,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.text.secondary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.text.secondary,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.text.secondary,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.text.secondary,
      },
    },
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage:
      "url(https://images.unsplash.com/photo-1530274083826-2facadf42457?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1268&q=80%27)",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.background.default,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  spaceAfter: {
    marginBottom: 15
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 1),
    backgroundColor: "#dcd8d8",
  },
  link: {
    color: theme.palette.text.secondary,
  },
  copyrights: {
    position: "absolute",
    bottom: "5px",
  },
  socialButton: {
    marginBottom: "25px",
    width: '100%'
  },
}));

type Props = {
  config: AppConfig
}

export const Login = (props: Props) => {
  const { config } = props;
  const facebookAppId = config ? config.socialApps.facebook : null;
  const googleAppId = config ? config.socialApps.google : null;
  const microsoftAppId = config ? config.socialApps.microsoft : null;

  const userContext = React.useContext(UserContext);
  const classes = useStyles();

  const [login, setLogin] = React.useState(false);
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = React.useState({});
  const [mode, setMode] = React.useState("login"); // modes: login, reset_password, text
  const handleChange = (event) => {
    if (event.target.name === "email") {
      if ("email" in errors) {
        setErrors({});
      }
    }
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (mode === "login") {
      axios
        .post("/rest-auth/login/", formData)
        .then((res) => {
          const user = {
            email: res.data.user.email,
            first_name: res.data.user.first_name,
            team: res.data.user.team,
            is_team_leader: res.data.user.is_team_leader,
            is_staff: res.data.user.is_staff,
            isAuthenticated: true,
          };
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(user));
          userContext.setUser(user);
          setLogin(true);
        })
        .catch((err) => {
          if (err && err.response && err.response.data && err.response.data) {
            setErrors(err.response.data);
          }
        });
    } else {
      axios
        .post("/rest-auth/password/reset/", { email: formData.email })
        .then((res) => {
          setMode("text");
        })
        .catch((err) => {
          if (err && err.response && err.response.data && err.response.data) {
            setErrors(err.response.data);
            console.log(err.response.data);
          }
        });
    }
  };

  const responseSocial = (provider, accessToken) => {
      axios
        .post(`/rest-auth/${provider}/`, {
          access_token: accessToken,
        })
        .then((res) => {
          const user = {
            email: res.data.user.email,
            first_name: res.data.user.first_name,
            team: res.data.user.team,
            is_team_leader: res.data.user.is_team_leader,
            is_staff: res.data.user.is_staff,
            isAuthenticated: true,
          };
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(user));
          userContext.setUser(user);
          setLogin(true);
        })
        .catch((err) => {
          if (err && err.response && err.response.data && err.response.data) {
            setErrors(err.response.data);
          }
        });
  };

  const responseFacebook = (res) => {
    if (res && res.accessToken) {
      responseSocial('facebook', res.accessToken)
    }
  };

  const responseGoogle = (res) => {
    if (res && res.access_token) {
      responseSocial('google', res.access_token)
    }
  };

  const responseMicrosoft = (res) => {
    if (res && res.access_token) {
      responseSocial('microsoft', res.access_token)
    }
  };

  if (login) {
    return <Redirect to="/calendar" />;
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            <span className={classes.spaceAfter}>{mode === "login" ? "Sign in" : "Reset password"}</span>
          </Typography>

          {"non_field_errors" in errors && (
            <Typography
              variant="caption"
              color="error"
              display="block"
              align="center"
            >
              <span className={classes.spaceAfter}>{errors.non_field_errors}</span>
            </Typography>
          )}

          {mode === "login" && googleAppId && (
            <LoginSocialGoogle
                className={classes.socialButton}
                client_id={googleAppId}
                scope="openid profile email"
                discoveryDocs="claims_supported"
                access_type="offline"
                onResolve={({ provider, data }) => responseGoogle(data)}
                onReject={err => console.log(err)}>
              <GoogleLoginButton text={"SIGN IN WITH GOOGLE"}/>
            </LoginSocialGoogle>
          )}

          {mode === "login" && microsoftAppId && (
            <LoginSocialMicrosoft
                className={classes.socialButton}
                redirect_uri={window.location.origin + window.location.pathname}
                scope={'profile openid email User.Read'}
                client_id={microsoftAppId}
                onResolve={({ provider, data }) => responseMicrosoft(data)}
                onReject={err => console.log(err)}>
              <MicrosoftLoginButton />
            </LoginSocialMicrosoft>
          )}

          {mode === "login" && facebookAppId && (
            <LoginSocialFacebook
                className={classes.socialButton}
                appId={facebookAppId}
                onResolve={({ provider, data }) => responseFacebook(data)}
                onReject={err => console.log(err)}>
                <FacebookLoginButton text={"SIGN IN WITH FACEBOOK"}/>
            </LoginSocialFacebook>
          )}

          {mode === "login" && (facebookAppId || googleAppId) && <>
            <hr />
            <span>OR</span>
            <hr />
          </>}


          {mode !== "text" ? (
            <form className={classes.form} onSubmit={handleSubmit}>
              <CssTextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                type="email"
                autoFocus
                onChange={handleChange}
                error={"email" in errors}
                helperText={"email" in errors && errors.email}
              />
              {mode === "login" && (
                <CssTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={handleChange}
                />
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
              >
                {mode === "login" ? "Sign In" : "Reset password"}
              </Button>
            </form>
          ) : (
            <Box mt={6}>
              <Typography>
                Please follow the instructions sent to {formData.email} to reset
                your password.
              </Typography>
            </Box>
          )}
          {mode === "login" ? (
            <Box style={{ width: "100%" }}>
              <Button size="small" onClick={() => setMode("reset_password")}>
                <Typography variant="caption">Forgot password?</Typography>
              </Button>
            </Box>
          ) : mode === "reset_password" ? (
            <Box mt={2} style={{ width: "100%" }}>
              <Button size="small" onClick={() => setMode("login")}>
                <Typography variant="caption">Back to login</Typography>
              </Button>
            </Box>
          ) : (
            <Box mt={4} align="center" style={{ width: "100%" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setFormData({ email: "", password: "" });
                  setMode("login");
                }}
              >
                <Typography variant="caption">Back to login</Typography>
              </Button>
            </Box>
          )}
          <Box mt={5} className={classes.copyrights}>
            <PrivacyPolicy />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};
