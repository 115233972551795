import React from "react";
import {
  Avatar,
  Box,
  Container,
  Grid,
  Grow,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  SvgIcon,
  TextField,
  Typography,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ExpantionPanelItem from "./commponents/ExpantionPanelItem";
import YouTube from "react-youtube";
import filters from "utils/search";
import SongMenuButton from "components/SongMenuButton";
import { UserContext } from "contexts/UserContext";
import BibleReferenceButton from "components/BibleReference/BibleReferenceButton";

const useStyles = makeStyles((theme) => ({
  active: { backgroundColor: theme.palette.secondary.main },
  scroll: { maxHeight: "100%", overflow: "auto" },
  listScrollWrapper: {
    height: "calc(100vh - 132px)",
  },
  mediaScrollWrapper: {
    position: "relative",
    height: "calc(100vh - 482px)",
  },
  youtubeBox: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    paddingBottom: "20px",
  },
  youtube: { alignContent: "center", justify: "center", flex: 1 },
  lyrics: { whiteSpace: "pre-wrap", overflow: "auto", maxHeight: "100%" },
  spotifyButton: {
    transition: "0.2s ease-in-out",
    "&:hover": {
      color: "#1DB954",
    },
  },
  actionButtons: {
    position: "absolute",
    top: 0,
    right: 0,
  },
}));

export default (props) => {
  const userContext = React.useContext(UserContext);
  const [state, setState] = React.useState({
    selected: props.songs ? props.songs[0] : null,
    query: null,
  });

  let songs = props.songs;

  const searchField = (
    <TextField
      fullWidth
      value={state.query || ""}
      label="Search"
      color="secondary"
      onChange={(e) => setState({ ...state, query: e.target.value })}
    />
  );
  const classes = useStyles();
  const { menuActions, events } = props;

  if (state.query) {
    const queryFilter = filters.queryFilter(state.query, (song) =>
      [song.title, song.artist].join(" ")
    );
    songs = queryFilter(props.songs);
    if (songs.length > 0 && songs.every((s) => s.id !== state.selected.id)) {
      state.selected = songs[0];
    }
  }

  return (
    <Grid container spacing={4}>
      <Hidden lgUp>
        <Grid item xs={12}>
          {searchField}
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.scroll}>
            <Grow
              in={true}
              style={{ transformOrigin: "50% 0 0" }}
              timeout={500}
            >
              <div>
                {songs.map((song) => (
                  <ExpantionPanelItem
                    song={song}
                    key={song.id}
                    actions={menuActions}
                    events={events}
                  />
                ))}
              </div>
            </Grow>
          </Box>
        </Grid>
      </Hidden>
      <Hidden mdDown>
        <Grid item xs={4} className={classes.listScrollWrapper}>
          {searchField}
          <Box className={classes.scroll} mt={2}>
            <List>
              {songs.map((song) => {
                return (
                  <ListItem
                    className={
                      state.selected.id === song.id ? classes.active : ""
                    }
                    key={song.id}
                    onClick={() => setState({ selected: song })}
                    button
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={`http://img.youtube.com/vi/${song.youtube_id}/0.jpg`}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={song.title}
                      secondary={song.artist}
                    />
                    <ListItemSecondaryAction>
                      {!!(
                        userContext.user.is_team_leader |
                        userContext.user.is_staff
                      ) && (
                        <SongMenuButton
                          song={{
                            ...song,
                            youtube_link: song.youtube_link,
                            lyrics: song.lyrics,
                          }}
                          actions={menuActions}
                          events={events}
                        />
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Container maxWidth="md">
            {state.selected && (
              <>
                {state.selected && (
                  <YouTube
                    className={classes.youtube}
                    videoId={state.selected.youtube_id}
                    containerClassName={classes.youtubeBox}
                    opts={{ width: "100%" }}
                  />
                )}
                <Box className={classes.mediaScrollWrapper}>
                  <Box className={classes.actionButtons}>
                    <BibleReferenceButton song={state.selected} />
                    {state.selected.spotify_id && (
                      <IconButton
                        href={`spotify:track:${state.selected.spotify_id}`}
                        title="Open in Spotify"
                        className={classes.spotifyButton}
                      >
                        <SvgIcon>
                          <path d="M19.098 10.638c-3.868-2.297-10.248-2.508-13.941-1.387-.593.18-1.22-.155-1.399-.748-.18-.593.154-1.22.748-1.4 4.239-1.287 11.285-1.038 15.738 1.605.533.317.708 1.005.392 1.538-.316.533-1.005.709-1.538.392zm-.126 3.403c-.272.44-.847.578-1.287.308-3.225-1.982-8.142-2.557-11.958-1.399-.494.15-1.017-.129-1.167-.623-.149-.495.13-1.016.624-1.167 4.358-1.322 9.776-.682 13.48 1.595.44.27.578.847.308 1.286zm-1.469 3.267c-.215.354-.676.465-1.028.249-2.818-1.722-6.365-2.111-10.542-1.157-.402.092-.803-.16-.895-.562-.092-.403.159-.804.562-.896 4.571-1.045 8.492-.595 11.655 1.338.353.215.464.676.248 1.028zm-5.503-17.308c-6.627 0-12 5.373-12 12 0 6.628 5.373 12 12 12 6.628 0 12-5.372 12-12 0-6.627-5.372-12-12-12z" />
                        </SvgIcon>
                      </IconButton>
                    )}
                  </Box>
                  <Typography className={classes.lyrics}>
                    {state.selected.lyrics || ""}
                  </Typography>
                </Box>
              </>
            )}
          </Container>
        </Grid>
      </Hidden>
    </Grid>
  );
};
