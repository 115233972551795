import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import songsAPI from "../../../../restApi/songsApi";


const useStyles = makeStyles((theme) => ({
  songInput: {},
  searchInput: {
    "& > * + *": {
      marginTop: theme.spacing(3),
      flexDirection: "column-reverse",
      alignItems: "start",
    },
    "& > * > input": {
      marginTop: theme.spacing(0),
      width: "100% !important",
    },
  },
}));

export default ({ eventModal, modifiedContent, setModifiedContent }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [options, setOptions] = React.useState(eventModal.songs);
  
  const loadData = () => {
    if (loading) {
      songsAPI.getSongs()
          .then((res) => {
          setOptions(res.data ? res.data : []);
          setLoading(false);
        })
    }};

  const handleChange = (event, value, reason) => {
    setModifiedContent({ ...modifiedContent, songs: value });
  };

  return (
    <Autocomplete
      multiple
      id="songSelect"
      options={options}
      getOptionLabel={(option) => `${option.title} - ${option.artist}`}
      defaultValue={eventModal.songs}
      filterSelectedOptions
      disableClearable={true}
      className={classes.songInput}
      loading={loading}
      onOpen={loadData}
      onChange={handleChange}
      getOptionSelected={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          color="secondary"
          label="Songs"
          placeholder="Add songs"
          className={classes.searchInput}
        />
      )}
    />
  );
};
