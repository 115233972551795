import React from "react";
import moment from "moment";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  SvgIcon,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  Edit as EditIcon,
  WhatsApp as WhatsAppIcon,
  YouTube as YouTubeIcon,
} from "@material-ui/icons";
import AccordionItem from "routes/Catalog/commponents/ExpantionPanelItem";
import styles from "./eventModal.module.scss";

export default ({
  fullScreen,
  classes,
  eventModal,
  closeEventModal,
  user,
  toggleEditMode,
  menuActions,
}) => {
  const songs = eventModal.songs.map((song, index) => (
    <AccordionItem
      song={song}
      key={song.id}
      itemIndex={index}
      actions={menuActions}
      event={eventModal}
    />
  ));
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const timeStart = moment(eventModal.timeStart)
    .utc()
    .format("DD MMM YYYY HH:mm");
  const duration = durationString(eventModal.timeStart, eventModal.timeEnd);

  /*const handleUpdateSpotifyPlaylist = () => {
    axios
      .post(
        "/api/update-spotify-playlist",
        {
          id: eventModal.id,
        },
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };*/

  const handleWhatsAppFormat = () => {
    const title = `*${eventModal.title}*`;
    const time =
      eventModal.timeStart
        .toLocaleString("en-GB", { timeZone: "UTC" })
        .replace(",", " at")
        .slice(0, -3) +
      "-" +
      eventModal.timeEnd
        .toLocaleTimeString("en-GB", { timeZone: "UTC" })
        .slice(0, -3);
    const details = eventModal.details ? eventModal.details : "";
    const songList = eventModal.songs
      .map((song) => {
        const songTitle = `*${song.title}*
`;
        const songArtist = song.artist
          ? `by ${song.artist}
`
          : "";
        const songLink = song.youtube_id
          ? `https://youtu.be/${song.youtube_id}
`
          : "";
        return songTitle + songArtist + songLink;
      })
      .join("\n");
    const link = `*Event link*
${window.location.href}`;

    navigator.clipboard.writeText(
      [title, time, details, "", songList, "", link].join("\n")
    );
    setOpenSnackbar(true);
  };

  const handlePlaylistPlay = () => {
    const playlistLink =
      "https://www.youtube.com/watch_videos?video_ids=" +
      eventModal.songs.map((song) => song.youtube_id).join();
    window.open(playlistLink, "_blank");
  };

  return eventModal.loading ? (
    <CircularProgress
      style={{
        display: "block",
        left: "50%",
        top: "50%",
        position: "absolute",
        color: eventModal.team.color,
        zIndex: 100,
      }}
    />
  ) : (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth="md"
      open={eventModal.isOpen}
      onClose={closeEventModal}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        classes: {
          root: classes.paper,
        },
      }}
    >
      <DialogTitle
        id="responsive-dialog-title"
        classes={{
          root: classes.dialogTitle,
        }}
      >
        {eventModal.title}
        <div id={styles.modalCloseButton}>
          {eventModal.spotify_playlist_id && (
            <IconButton
              href={`spotify:playlist:${eventModal.spotify_playlist_id}`}
              title="Open in Spotify"
            >
              <SvgIcon>
                <path d="M19.098 10.638c-3.868-2.297-10.248-2.508-13.941-1.387-.593.18-1.22-.155-1.399-.748-.18-.593.154-1.22.748-1.4 4.239-1.287 11.285-1.038 15.738 1.605.533.317.708 1.005.392 1.538-.316.533-1.005.709-1.538.392zm-.126 3.403c-.272.44-.847.578-1.287.308-3.225-1.982-8.142-2.557-11.958-1.399-.494.15-1.017-.129-1.167-.623-.149-.495.13-1.016.624-1.167 4.358-1.322 9.776-.682 13.48 1.595.44.27.578.847.308 1.286zm-1.469 3.267c-.215.354-.676.465-1.028.249-2.818-1.722-6.365-2.111-10.542-1.157-.402.092-.803-.16-.895-.562-.092-.403.159-.804.562-.896 4.571-1.045 8.492-.595 11.655 1.338.353.215.464.676.248 1.028zm-5.503-17.308c-6.627 0-12 5.373-12 12 0 6.628 5.373 12 12 12 6.628 0 12-5.372 12-12 0-6.627-5.372-12-12-12z" />
              </SvgIcon>
            </IconButton>
          )}
          {!!(user.is_staff | user.is_team_leader) && (
            <IconButton
              aria-label="WhatsApp Icon"
              size={fullScreen ? "small" : "medium"}
              title="Copy in WhatsApp format"
              onClick={handleWhatsAppFormat}
              className={fullScreen ? classes.dialogTitleButton : ""}
              disabled={openSnackbar}
            >
              <WhatsAppIcon />
            </IconButton>
          )}
          <IconButton
            aria-label="YouTube icon"
            size={fullScreen ? "small" : "medium"}
            title="Play on YouTube"
            onClick={handlePlaylistPlay}
            className={fullScreen ? classes.dialogTitleButton : ""}
          >
            <YouTubeIcon />
          </IconButton>
          {!!(user.is_staff | user.is_team_leader) && (
            <IconButton
              aria-label="edit"
              onClick={toggleEditMode}
              size={fullScreen ? "small" : "medium"}
              title="Edit"
              className={fullScreen ? classes.dialogTitleButton : ""}
            >
              <EditIcon />
            </IconButton>
          )}
          <IconButton
            aria-label="close"
            onClick={closeEventModal}
            size={fullScreen ? "small" : "medium"}
            title="Close"
            className={fullScreen ? classes.dialogTitleButton : ""}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent
        classes={{
          root: classes.dialogContent,
        }}
      >
        {eventModal.team && (
          <p
            className={styles.modalInfo + " " + classes.modalTeam}
            style={{
              color: eventModal.team.color,
              borderColor: eventModal.team.color,
            }}
          >
            {eventModal.team.name}
          </p>
        )}
        {eventModal.timeStart && (
          <p id={styles.modalTime} className={styles.modalInfo}>
            {timeStart}
          </p>
        )}
        {eventModal.timeEnd && (
          <p id="modalDuration" className={styles.modalInfo}>
            {"Duration: " + duration}
          </p>
        )}
        {eventModal.details && (
          <p id={styles.modalDetails} className={styles.modalInfo}>
            {eventModal.details}
          </p>
        )}
        {eventModal.songs.length > 0 && (
          <div className={styles.songList}>{songs}</div>
        )}
      </DialogContent>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        onClose={() => {
          setOpenSnackbar(false);
        }}
        message="Copied to clipboard!"
        autoHideDuration={2000}
      />
    </Dialog>
  );
};

const durationString = (timeStart, timeEnd) => {
  const timeDuration = moment.duration(moment(timeEnd).diff(timeStart));
  let duration = [];
  if (timeDuration.days()) {
    if (timeDuration.days() === 1) {
      duration.push(timeDuration.days() + " day");
    } else {
      duration.push(timeDuration.days() + " days");
    }
  }
  if (timeDuration.hours()) {
    if (timeDuration.hours() === 1) {
      duration.push(timeDuration.hours() + " hour");
    } else {
      duration.push(timeDuration.hours() + " hours");
    }
  }
  if (timeDuration.minutes()) {
    if (timeDuration.minutes() === 1) {
      duration.push(timeDuration.minutes() + " minute");
    } else {
      duration.push(timeDuration.minutes() + " minutes");
    }
  }
  duration = duration.join(", ");
  const last_comma = duration.lastIndexOf(",");
  if (last_comma > 0) {
    duration =
      duration.slice(0, last_comma) + " and" + duration.slice(last_comma + 1);
  }
  return duration;
};
