import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

export default ({ song, action, open, handleClose }) => {
  return (
    <Dialog
      open={Boolean(open)}
      onClose={handleClose}
      aria-labelledby="action-confirmation-text"
      aria-describedby="action-dialog-description"
    >
      <DialogTitle id="action-confirmation-text">
        {song.title}{" "}
        {song.artist && <Typography variant="body1">{song.artist}</Typography>}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="action-dialog-description">
          {action === "add" &&
            "Are you sure you want to add this song to the catalog?"}
          {action === "delete" && "Are you sure you want to delete this song?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="default">
          No
        </Button>
        <Button onClick={() => handleClose(true)}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
};
