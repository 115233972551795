import React from "react";
import { Box, Fade, useTheme } from "@material-ui/core";
import EventModal from "./components/EventModal/eventModal";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // for selectable
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "./Calendar.scss";

const brightness = (color) => {
  const hasFullSpec = color.length === 7;
  var m = color.substr(1).match(hasFullSpec ? /(\S{2})/g : /(\S{1})/g);
  if (m) {
    const r = parseInt(m[0] + (hasFullSpec ? "" : m[0]), 16);
    const g = parseInt(m[1] + (hasFullSpec ? "" : m[1]), 16);
    const b = parseInt(m[2] + (hasFullSpec ? "" : m[2]), 16);

    return (r + g + b) / 3;
  }
  return 0;
};

export default (props) => {
  //const [selectInfo, setSelectInfo] = React.useState({});
  const theme = useTheme();
  const themeBackgroundColor = theme.palette.background.default;

  return (
    <div
      className={
        brightness(themeBackgroundColor) < 100
          ? "calendar dark"
          : "calendar light"
      }
    >
      <EventModal {...props} />
      <Fade in={true} timeout={1000}>
        <Box className="calendarWrapper">
          <FullCalendar
            defaultView="dayGridMonth"
            plugins={[interactionPlugin, dayGridPlugin]}
            selectable={true}
            height="parent"
            events={props.events}
            firstDay={1}
            /*select={function(info) {
              setSelectInfo(info);
            }}*/
            header={{
              left: "title",
              center: "none",
              right: "add, prev, next",
            }}
            fixedWeekCount={false}
            timeFormat="HH:mm"
            timeZone="UTC"
            eventTimeFormat={{
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
              omitZeroMinute: false,
              meridiem: false,
            }}
            eventClick={(info) => {
              props.getEventInfo(info.event.extendedProps.id);
            }}
            select={(info) => {
              props.handleDateSelect(info);
            }}
            unselect={props.handleDateUnselect}
            unselectCancel=".fc-add-button"
            customButtons={{
              add: {
                icon: "add",
                click: function (event) {
                  event.preventDefault();
                  props.openEventCreationModal();
                },
              },
            }}
          />
        </Box>
      </Fade>
    </div>
  );
};
