import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  IconButton,
  TextField,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import SongSelect from "./SongSelect";
import styles from "./eventModal.module.scss";
import eventsAPI from "restApi/eventsApi";

export default ({
  fullScreen,
  classes,
  updateEvent,
  deleteEvent,
  eventModal,
  setEventModal,
  closeEventModal,
  toggleEditMode,
}) => {
  const [modifiedContent, setModifiedContent] = React.useState({
    ...eventModal,
    spotify_playlist: eventModal.spotify_playlist_id ? true : false,
  });

  const [errors, setErrors] = React.useState({});

  const handleTextChange = (event) => {
    setModifiedContent({
      ...modifiedContent,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckboxChange = (event) => {
    setModifiedContent({
      ...modifiedContent,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    /* 
    I got a lot of head ache from messing with timezones. 
    This works, but it's a hack... Might need a review.
    */
    eventsAPI
      .patchEvent(eventModal.id, {
        name: modifiedContent.title,
        songs: modifiedContent.songs.map((s) => s.id),
        details: modifiedContent.details,
        time_start: modifiedContent.timeStart,
        time_end: modifiedContent.timeEnd,
        spotify_playlist: modifiedContent.spotify_playlist,
      })
      .then((res) => {
        setEventModal({
          ...modifiedContent,
          timeStart:
            modifiedContent.timeStart !== eventModal.timeStart
              ? new Date(modifiedContent.timeStart + "Z")
              : eventModal.timeStart,
          timeEnd:
            modifiedContent.timeEnd !== eventModal.timeEnd
              ? new Date(modifiedContent.timeEnd + "Z")
              : eventModal.timeEnd,
          spotify_playlist_id: res.data.spotify_playlist_id,
        });
        updateEvent({
          ...modifiedContent,
        });
        toggleEditMode();
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      });
  };

  const handleDelete = async () => {
    eventsAPI.deleteEvent(eventModal.id).then((res) => {
      deleteEvent(eventModal.id);
      closeEventModal();
    });
  };

  const defaultTimeStart = eventModal.timeStart
    ? eventModal.timeStart.toISOString().slice(0, -5)
    : null;
  const defaultTimeEnd = eventModal.timeEnd
    ? eventModal.timeEnd.toISOString().slice(0, -5)
    : null;

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth="md"
      open={eventModal.isOpen}
      onClose={closeEventModal}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        classes: {
          root: classes.paper,
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle
          id="responsive-dialog-title"
          classes={{
            root: classes.dialogTitle,
          }}
        >
          <TextField
            name="title"
            id="event-name-input"
            label="Name of event"
            defaultValue={eventModal.title}
            color="secondary"
            onChange={handleTextChange}
            error={"name" in errors}
            helperText={"name" in errors && errors.name}
            required
          />
          <div id={styles.modalCloseButton}>
            <IconButton
              aria-label="delete"
              onClick={handleDelete}
              size={fullScreen ? "small" : "medium"}
              title="delete"
              className={fullScreen ? classes.dialogTitleButton : ""}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              aria-label="edit"
              onClick={toggleEditMode}
              size={fullScreen ? "small" : "medium"}
              title="Edit"
              className={fullScreen ? classes.dialogTitleButton : ""}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="close"
              onClick={closeEventModal}
              size={fullScreen ? "small" : "medium"}
              title="Close"
              className={fullScreen ? classes.dialogTitleButton : ""}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent
          classes={{
            root: classes.dialogContent,
          }}
        >
          <Box mt={2}>
            <TextField
              name="timeStart"
              label="Start time"
              color="secondary"
              type="datetime-local"
              defaultValue={defaultTimeStart}
              InputLabelProps={{ shrink: true }}
              onChange={handleTextChange}
              error={"time_start" in errors}
              helperText={"time_start" in errors && errors.time_start}
              className={styles.datetimeField}
              required
            />
          </Box>
          <Box mt={2}>
            <TextField
              name="timeEnd"
              label="End time"
              color="secondary"
              type="datetime-local"
              defaultValue={defaultTimeEnd}
              InputLabelProps={{ shrink: true }}
              onChange={handleTextChange}
              error={"time_end" in errors}
              helperText={"time_end" in errors && errors.time_end}
              className={styles.datetimeField}
              required
            />
          </Box>
          <Box mt={2}>
            <TextField
              name="details"
              label="Details"
              color="secondary"
              multiline
              fullWidth
              defaultValue={eventModal.details || ''}
              onChange={handleTextChange}
            />
          </Box>
          <Box mt={2}>
            <SongSelect
              eventModal={eventModal}
              modifiedContent={modifiedContent}
              setModifiedContent={setModifiedContent}
            />
          </Box>
          <Box mt={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={modifiedContent.spotify_playlist}
                  onChange={handleCheckboxChange}
                  name="spotify_playlist"
                />
              }
              label="Spotify playlist?"
            />
            {"spotify_playlist" in errors && (
              <FormHelperText error>{errors.spotify_playlist}</FormHelperText>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleEditMode}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
