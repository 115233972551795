import React from "react";
import { UserContext } from "../../../contexts/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  Favorite as FavoriteIcon,
  PlayArrow as PlayArrowIcon,
  Menu as MenuIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import YouTube from "react-youtube";
import "./Button.css";
import songsAPI from "../../../restApi/songsApi";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    position: "relative",
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  cardHeader: {
    position: "relative",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  lyricsContent: {
    whiteSpace: "pre-wrap",
  },
  cardActioins: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(2),
  },
  likesButton: {
    color: "#e85b7c",
    outline: "none",
  },
  spotifyButton: {
    transition: "0.3s ease-in-out",
    '&:hover':{
      color: "#1DB954",
    }
  },
  playButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#00000090",
    color: "white",
  },
  dialogConfirmButton: {
    backgroundColor: "#00ff00",
  },
}));

export default ({ song, handleOpenActionDialog, handleOpenUpdateDialog }) => {
  const userContext = React.useContext(UserContext);
  const classes = useStyles();
  const [loadedVideo, setLoadedVideo] = React.useState(false);
  const [videoPlaying, setVideoPlaying] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [userLikesSong, setUserLikesSong] = React.useState(Boolean(song.liked));
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handlePlayClick = () => {
    setLoadedVideo(true);
  };

  const handlePlay = () => {
    setVideoPlaying(true);
  };

  const handleStop = () => {
    setVideoPlaying(false);
  };

  const handleLikeClick = () => {
    songsAPI.post(
        "api/song_proposal_vote/",
        { id: parseInt(song.url.match(/(\d*)\/$/)[1]) }
      )
      .then((res) => {})
    setUserLikesSong(!userLikesSong);
    song.vote_score = userLikesSong ? song.vote_score - 1 : song.vote_score + 1;
  };

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleAddToCatalog = (song) => {
    handleMenuClose();
    handleOpenActionDialog({ song, action: "add" });
  };

  const handleEdit = () => {
    handleOpenUpdateDialog(song);
    handleMenuClose();
  };

  const handleDelete = (song) => {
    handleMenuClose();
    handleOpenActionDialog({ song, action: "delete" });
  };

  var videoOptions = {
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <Grid
      item
      xs={12}
      sm={videoPlaying ? 12 : 6}
      md={videoPlaying ? 8 : 4}
      lg={videoPlaying ? 6 : 3}
      xl={videoPlaying ? 4 : 2}
    >
      <Card className={classes.card}>
        {loadedVideo ? (
          <YouTube
            videoId={song.youtube_id}
            className={classes.video}
            containerClassName={classes.media}
            opts={videoOptions}
            onPlay={handlePlay}
            onPause={handleStop}
            onEnd={handleStop}
          />
        ) : (
          <CardMedia
            className={classes.media}
            image={"https://img.youtube.com/vi/" + song.youtube_id + "/0.jpg"}
            title={song.title}
          >
            <div>
              <Button
                variant="contained"
                onClick={handlePlayClick}
                className={classes.playButton}
              >
                <PlayArrowIcon />
              </Button>
            </div>
          </CardMedia>
        )}

        <CardContent className={classes.cardHeader}>
          <Typography variant="h6" color="textSecondary" component="p">
            {song.title}
          </Typography>
          {song.artist && (
            <Typography variant="body2" color="textSecondary" component="p">
              {song.artist}
            </Typography>
          )}
          <div className={classes.cardActioins}>
            {song.spotify_id && (
              <IconButton
                href={`spotify:track:${song.spotify_id}`}
                title="Open in Spotify"
                className={classes.spotifyButton}
              >
                <SvgIcon>
                  <path d="M19.098 10.638c-3.868-2.297-10.248-2.508-13.941-1.387-.593.18-1.22-.155-1.399-.748-.18-.593.154-1.22.748-1.4 4.239-1.287 11.285-1.038 15.738 1.605.533.317.708 1.005.392 1.538-.316.533-1.005.709-1.538.392zm-.126 3.403c-.272.44-.847.578-1.287.308-3.225-1.982-8.142-2.557-11.958-1.399-.494.15-1.017-.129-1.167-.623-.149-.495.13-1.016.624-1.167 4.358-1.322 9.776-.682 13.48 1.595.44.27.578.847.308 1.286zm-1.469 3.267c-.215.354-.676.465-1.028.249-2.818-1.722-6.365-2.111-10.542-1.157-.402.092-.803-.16-.895-.562-.092-.403.159-.804.562-.896 4.571-1.045 8.492-.595 11.655 1.338.353.215.464.676.248 1.028zm-5.503-17.308c-6.627 0-12 5.373-12 12 0 6.628 5.373 12 12 12 6.628 0 12-5.372 12-12 0-6.627-5.372-12-12-12z" />
                </SvgIcon>
              </IconButton>
            )}
            {!!(
              song.proposed_by_user |
              userContext.user.is_team_leader |
              userContext.user.is_staff
            ) && (
              <span>
                <IconButton
                  aria-label="Edit"
                  title="Edit"
                  className={classes.menuButton}
                  aria-controls="songOptions"
                  aria-haspopup="true"
                  onClick={handleMenuClick}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="songOptions"
                  anchorEl={menuAnchorEl}
                  keepMounted
                  open={Boolean(menuAnchorEl)}
                  onClose={handleMenuClose}
                >
                  {!!(
                    userContext.user.is_team_leader | userContext.user.is_staff
                  ) && (
                    <div>
                      <MenuItem onClick={() => handleAddToCatalog(song)}>
                        Add to catalog
                      </MenuItem>
                      <Divider />
                    </div>
                  )}
                  <MenuItem onClick={handleEdit}>Edit</MenuItem>
                  <Divider />
                  <MenuItem onClick={() => handleDelete(song)}>Delete</MenuItem>
                </Menu>
              </span>
            )}
          </div>
        </CardContent>
        <Divider variant="middle" />
        <CardActions disableSpacing>
          <IconButton
            aria-label="Vote"
            onClick={handleLikeClick}
            title={userLikesSong ? "Unlike" : "Like"}
          >
            <FavoriteIcon
              className={userLikesSong ? classes.likesButton : ""}
            />
          </IconButton>
          <Typography variant="body2">{song.vote_score + " votes"}</Typography>
          <span style={{ marginLeft: "auto" }}>
            {song.lyrics && (
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            )}
          </span>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.lyricsContent}
            >
              {song.lyrics}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
};
