var filters = {};

filters.queryFilter = function(query, nameFunc) {
    query = query.toLowerCase().split(" ");
    return function(items) {
        var priorities = [];
        Object.keys(items).forEach(function(k, i) {
            var item = items[k]
            var name = nameFunc(item);
            var words = name.split(" ").map(function(word) {
                return word.replace(/\(|\)/g, '').toLowerCase();
            });
            var priority = -1;
            for (var q = 0; q < query.length; q++) {
                var match = false;
                var qPriority = Number.POSITIVE_INFINITY;
                for (var w = 0; w < words.length; w++) {
                    var queryIndex = words[w].indexOf(query[q]);
                    if (queryIndex !== -1) {
                        match = true;
                        if (queryIndex === 0) {
                            qPriority = Math.min(qPriority, w);
                        } else {
                            qPriority = Math.min(qPriority, 10);
                        }
                    }
                }
                if (!match) {
                    return;
                } else {
                    priority = Math.max(priority, qPriority);
                }
            }
            priorities.push({
                priority: priority,
                index: i
            });
        });
        priorities = priorities.sort(function(a, b) {
            if (a.priority===b.priority) {
                return a.index - b.index;
            }
            return a.priority - b.priority;
        });
        return priorities.map(function (item) {
            return items[item.index];
        });
    };
};

filters.stringCompare = function(a, b) {
    if (a) {
        a = a.toLowerCase().trim();
    }
    if (b) {
        b = b.toLowerCase().trim();
    }
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
};

filters.alphaFilter = function(nameFunc) {
    return function(items) {
        return items.sort(function(a, b) {
            var nameA = nameFunc(a), nameB = nameFunc(b);
            return filters.stringCompare(nameA, nameB);
        });
    };
};

module.exports = filters;
