import React from "react";
import {
  Box,
  Button,
  Paper,
  Snackbar,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  Check as CheckIcon,
  PersonAdd as PersonAddIcon,
} from "@material-ui/icons";
import SpotifyIcon from "components/SvgIcons/Spotify";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => {
  return {
    spotifyInfoBox: {
      backgroundColor: "#1DB954",
      maxWidth: "340px",
      borderRadius: "4px",
    },
    stickyHeaderPaper: {
      backgroundColor: theme.palette.background.paper,
    },
    tableContainer: {
      maxHeight: "calc(100vh - 400px)",
    },
  };
});

const membersTable = (users, classes, largeScreen) => (
  <TableContainer className={classes.tableContainer}>
    <Table
      stickyHeader={true}
      size={largeScreen ? "medium" : "small"}
      aria-label="members table"
    >
      <TableHead>
        <TableRow>
          <TableCell align="left" className={classes.stickyHeaderPaper}>
            Name
          </TableCell>
          {largeScreen && (
            <TableCell align="left" className={classes.stickyHeaderPaper}>
              Email
            </TableCell>
          )}
          <TableCell align="center" className={classes.stickyHeaderPaper}>
            Leader
          </TableCell>
          <TableCell align="center" className={classes.stickyHeaderPaper}>
            Admin
          </TableCell>
          {largeScreen && (
            <TableCell align="left" className={classes.stickyHeaderPaper}>
              Date Joined
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {users &&
          users
            .sort((a, b) => a.first_name > b.first_name)
            .map((user) => (
              <TableRow key={user.id}>
                <TableCell component="th" scope="row">
                  {`${user.first_name} ${user.last_name}`}
                </TableCell>
                {largeScreen && (
                  <TableCell align="left">{user.email}</TableCell>
                )}
                <TableCell align="center">
                  {user.is_team_leader && <CheckIcon />}
                </TableCell>
                <TableCell align="center">
                  {user.is_team_admin && <CheckIcon />}
                </TableCell>
                {largeScreen && (
                  <TableCell align="left">
                    {new Date(user.date_joined).toLocaleDateString("en-GB")}
                  </TableCell>
                )}
              </TableRow>
            ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default ({
  team,
  createInvitationCode,
  connectToSpotify,
  disconnectSpotify,
  snackbarOpen,
  handleSnackbarClose,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const table = team ? membersTable(team.member, classes, largeScreen) : "";

  return (
    <Box mt={2}>
      <Paper>
        <Box p={2}>
          <Typography
            variant={largeScreen ? "h4" : "h5"}
          >{`${team.name}, ${team.city}`}</Typography>
          <Box mt={largeScreen ? 3 : 2}>
            <Button
              onClick={createInvitationCode}
              variant="outlined"
              size={largeScreen ? "medium" : "small"}
              startIcon={<PersonAddIcon />}
              title="Copy invitation link to clipboard"
            >
              Invitation Link
            </Button>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={handleSnackbarClose}
            >
              <Alert onClose={handleSnackbarClose} severity="success">
                Copied to clipboard!
              </Alert>
            </Snackbar>
          </Box>
          <Box mt={2}>
            {team.spotify_username ? (
              <Box p={2} className={classes.spotifyInfoBox} align="center">
                <Box mb={2} align="center">
                  <SpotifyIcon fontSize="large" />
                </Box>
                <Typography variant="h6">Connected to Spotify as</Typography>
                <Typography variant="h6">{team.spotify_username}</Typography>
                <Box mt={3} mb={1}>
                  <Button
                    onClick={disconnectSpotify}
                    variant="contained"
                    size={largeScreen ? "medium" : "small"}
                    align="right"
                  >
                    Disconnect from Spotify
                  </Button>
                </Box>
                <Typography variant="caption">
                  Disconnecting from Spotify will not delete playlists or their
                  existing links stored in events.
                </Typography>
              </Box>
            ) : (
              <Button
                variant="outlined"
                size={largeScreen ? "medium" : "small"}
                startIcon={<SpotifyIcon />}
                onClick={connectToSpotify}
              >
                Connect to Spotify
              </Button>
            )}
          </Box>
        </Box>
      </Paper>
      <Box mt={2} py={2}>
        <Box px={2} mb={1}>
          <Typography variant={largeScreen ? "h5" : "h6"}>Members</Typography>
        </Box>
        <Paper>{table}</Paper>
      </Box>
    </Box>
  );
};
