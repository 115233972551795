import axiosConfig from './axiosConfig';

const songsAPI = {};

songsAPI.get = (url) => axiosConfig.get(url);
songsAPI.post = (url, data) => axiosConfig.post(url, data);
songsAPI.getSongs = () => axiosConfig.get("/api/songs/");
songsAPI.getSong = (songId) => axiosConfig.get(`/api/songs/${songId}/`);
songsAPI.createSong = (data) => axiosConfig.post("/api/songs/", data);
songsAPI.createLyrics = (data) => axiosConfig.post("/api/lyrics/", data);
songsAPI.patch = (url, data) => axiosConfig.patch(url, data);
songsAPI.delete = (url) => axiosConfig.delete(url);

export default songsAPI;