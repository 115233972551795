import React from "react";
import { CircularProgress, Container } from "@material-ui/core";
import Catalog from "./Catalog";
import { Alert } from "react-bootstrap"; // This could easily be removed.
import songsAPI from "restApi/songsApi";
import eventsApi from "restApi/eventsApi";

export default () => {
  const [state, setState] = React.useState({
    songs: null,
    events: null,
    loading: true,
    error: null,
  });

  React.useEffect(() => {
    songsAPI.getSongs().then((res) => {
      const songs = res.data;
      eventsApi.getUpcomingEvents().then((res) => {
        const events = res.data;
        setState({ songs: songs, events: events, loading: false, error: null });
      });
    });
  }, []);

  const handleAssignEvent = (song, events) => {
    let updatedEvents = state.events;
    const initialEventsWithSong = state.events.filter(
      (e) => e.song_list.findIndex((songId) => songId === song.id) > -1
    );
    // Remove songs from events that are no longer assigned.
    initialEventsWithSong.forEach((ie) => {
      if (events.findIndex((e) => e.id === ie.id) === -1) {
        const index = updatedEvents.findIndex((ue) => ue.id === ie.id);
        let event = updatedEvents[index];
        updatedEvents.splice(index, 1);
        event.song_list = event.song_list.filter(
          (songId) => songId !== song.id
        );
        updatedEvents.push(event);
      }
    });
    // Add song to events that are newly assigned.
    events.forEach((e) => {
      if (
        initialEventsWithSong.findIndex((event) => event.id === e.id) === -1
      ) {
        const index = updatedEvents.findIndex((event) => event.id === e.id);
        let event = updatedEvents[index];
        updatedEvents.splice(index, 1);
        event.song_list.push(song.id);
        updatedEvents.push(event);
      }
    });
    setState({ ...state, events: updatedEvents });
  };

  const handleUpdateSong = (song) => {
    const { songs } = state;
    let s = songs.filter((s) => s.url !== song.url);
    s.push(song);
    setState({
      ...state,
      songs: s.sort((a, b) => a.title.localeCompare(b.title)),
    });
  };
  const handleDeleteSong = (song) => {
    const { songs } = state;
    setState({
      ...state,
      songs: songs.filter((s) => s.url !== song.url),
    });
  };

  return (
    <Container maxWidth="xl">
      {state.loading ? (
        <div
          style={{
            position: "absolute",
            top: "30%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : state.error ? (
        <Alert
          style={{ backgroundColor: "#424242", color: "rgb(191, 199, 199)" }}
        >
          Error: {state.error}
        </Alert>
      ) : state.songs && state.songs.length ? (
        <Catalog
          songs={state.songs}
          menuActions={{
            assignEvent: handleAssignEvent,
            edit: handleUpdateSong,
            delete: handleDeleteSong,
          }}
          events={state.events}
        />
      ) : (
        <Alert
          style={{ backgroundColor: "#424242", color: "rgb(191, 199, 199)" }}
        >
          <h5>There are no songs in your team's catalog yet!</h5>
          When Team leaders approve songs from the "Song Proposals" page, they
          will be listed here.
        </Alert>
      )}
    </Container>
  );
};
