import React from "react";
import { Button, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import userApi from "restApi/userApi";
import { UserContext } from '../../contexts/UserContext';

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        textAlign: "justify",
    }
}));


const AccountSettings = () => {
    const classes = useStyles();
    const userContext = React.useContext(UserContext);

    return (
        <Grid container component="main" className={classes.root}>
            <Grid item component={Paper} square>
                <div className={classes.paper}>
                    <h3>
                        Delete your account?
                    </h3>
                    <p>
                        If you delete your account all your social account information will be
                        removed.
                    </p>
                    <Button style={{ backgroundColor: 'red' }} variant="contained" onClick={() => {
                        userApi.delete("/api/unregister/")
                        .then((res) => {
                            localStorage.removeItem("token");
                            userContext.setUser({
                                isAuthenticated: false,
                                email: "",
                                first_name: "",
                                team: null,
                                team_leader: false,
                                admin: false,
                            });
                        })
                    }}>
                        Delete Account
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
};

export default AccountSettings;
