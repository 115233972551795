import axios from 'axios';
import { TOKEN_KEY } from '../CONSTANTS';

const axiosConfig = axios.create({
    //baseURL: API_BASE_URL || 'http://localhost:3000'
});

axiosConfig.interceptors.request.use(request => {
    request.headers.common['Authorization'] = `JWT ${localStorage.getItem(TOKEN_KEY)}`;
    return request;
}, Promise.reject);

// Add a response interceptor to catch errors and take action
axiosConfig.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, error => {
    axios.post("/api-token-verify/", {
        token: localStorage.getItem(TOKEN_KEY),
    })
    .catch(() => {
        localStorage.removeItem(TOKEN_KEY);
        window.location.href = '/login'; // TODO (yonni): implement next url to jump to after login
    });
    return Promise.reject(error);
});

export default axiosConfig;
