import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Slide,
  Tab,
  Tabs,
  Toolbar,
  useMediaQuery,
  useScrollTrigger,
} from "@material-ui/core";
import {
    Brightness3 as Brightness3Icon,
    Event as EventIcon,
    Grade as GradeIcon,
    List as ListIcon,
    Menu as MenuIcon,
    PowerSettingsNew as PowerSettingsNewIcon,
    NewReleases as NewReleasesIcon,
    Settings as SettingsIcon,
    WbSunny as WbSunnyIcon,
    AccountBox as AccountBoxIcon,
} from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import "./NavigationBar.scss";
import { UserContext } from "../../contexts/UserContext";

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const routeTable = {
  "/login": 0,
  "/calendar": 0,
  "/catalog": 1,
  "/song-proposals": 2,
};

const useStyles = makeStyles((theme) => ({
  noHeightConstraint: {
    minHeight: "unset",
    fontSize: "12px",
  },
  drawer: {
    width: 200,
  },
  verticalTabsIndicator: {
    left: 0,
    width: "3px",
  },
}));

export default ({ user, themePalette, setThemePalette }) => {
  const userContext = React.useContext(UserContext);
  const location = useLocation();
  const [value, setValue] = React.useState(routeTable[location.pathname]);
  const [DrawerOpen, setDrawerOpen] = React.useState(false);
  const [settingsAnchor, setSettingsAnchor] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleSettingsOpen = (event) => {
    setSettingsAnchor(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setSettingsAnchor(null);
  };

  const toggleThemePalette = () => {
    if (themePalette === "dark") {
      setThemePalette("light");
    } else {
      setThemePalette("dark");
    }
  };

  const handleAdminClick = () => {
    handleSettingsClose();
    handleDrawerClose();
  }

  const handleAdminRedirect = () => {
    window.open("/admin", "_blank");
  };

  const handleSignOut = () => {
    localStorage.removeItem("token");
    userContext.setUser({
      isAuthenticated: false,
      email: "",
      first_name: "",
      team: null,
      team_leader: false,
      admin: false,
    });
  };

  const classes = useStyles();
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const horizontalNavigationBar = (
    <Toolbar className={classes.noHeightConstraint}>
      <IconButton
        aria-label="Settings"
        aria-describedby="SettingsButton"
        edge="start"
        onClick={handleSettingsOpen}
        style={{ outline: "none" }}
      >
        <SettingsIcon />
      </IconButton>
      <Popover
        id="SettingsButton"
        anchorEl={settingsAnchor}
        open={Boolean(settingsAnchor)}
        onClose={handleSettingsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <List aria-label="Settings list">
          <ListItem
            button
            onClick={() => {
              handleSettingsClose();
              toggleThemePalette();
            }}
          >
            <ListItemIcon>
              {themePalette === "dark" ? <WbSunnyIcon /> : <Brightness3Icon />}
            </ListItemIcon>
            <ListItemText
              primary={
                themePalette === "dark"
                  ? "Enter bright mode"
                  : "Enter dark mode"
              }
            />
          </ListItem>
          {user.is_team_leader && (
            <ListItem
              button
              component={Link}
              to="/admin"
              onClick={handleAdminClick}
            >
              <ListItemIcon>
                <GradeIcon />
              </ListItemIcon>
              <ListItemText primary="Admin" />
            </ListItem>
          )}
          {user.is_staff && (
            <ListItem button onClick={handleAdminRedirect}>
              <ListItemIcon>
                <GradeIcon />
              </ListItemIcon>
              <ListItemText primary="Django Admin" />
            </ListItem>
          )}
          <ListItem
              button
              component={Link}
              to="/account"
              onClick={() => handleSettingsClose()}
          >
            <ListItemIcon>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Account" />
          </ListItem>
          <ListItem button onClick={handleSignOut}>
            <ListItemIcon>
              <PowerSettingsNewIcon />
            </ListItemIcon>
            <ListItemText primary="Sign out" />
          </ListItem>
        </List>
      </Popover>

      <Tabs
        value={value}
        indicatorColor="secondary"
        textColor="secondary"
        onChange={handleChange}
        aria-label="page tabs"
        style={{ marginLeft: "auto" }}
      >
        <Tab label="Calendar" component={Link} to="/calendar" />
        <Tab label="Catalog" component={Link} to="/catalog" />
        <Tab label="Song Proposals" component={Link} to="/song-proposals" />
      </Tabs>
    </Toolbar>
  );

  const verticalNavigationBar = (
    <Toolbar className={classes.noHeightConstraint}>
      <IconButton
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="end"
        style={{ marginLeft: "auto", outline: "none" }}
      >
        <MenuIcon />
      </IconButton>
    </Toolbar>
  );

  const verticalNavigationDrawer = (
    <div
      className={classes.drawer}
      role="presentation"
      onKeyDown={handleDrawerClose}
    >
      <Tabs
        orientation="vertical"
        value={value}
        indicatorColor="secondary"
        textColor="secondary"
        onChange={handleChange}
        onClick={handleDrawerClose}
        aria-label="page tabs"
        style={{ marginLeft: "auto" }}
        centered={false}
        classes={{ indicator: classes.verticalTabsIndicator }}
      >
        <Tab
          icon={<EventIcon />}
          label="Calendar"
          component={Link}
          to="/calendar"
        />
        <Tab
          icon={<ListIcon />}
          label="Catalog"
          component={Link}
          to="/catalog"
        />
        <Tab
          icon={<NewReleasesIcon />}
          label="Song Proposals"
          component={Link}
          to="/song-proposals"
        />
      </Tabs>

      <Tab
        icon={<SettingsIcon />}
        label="Options"
        aria-label="Settings"
        aria-describedby="SettingsButton"
        onClick={handleSettingsOpen}
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          outline: "none",
        }}
      />

      <Popover
        id="SettingsButton"
        anchorEl={settingsAnchor}
        open={Boolean(settingsAnchor)}
        onClose={handleSettingsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <List aria-label="Settings list">
          <ListItem
            button
            onClick={() => {
              handleSettingsClose();
              toggleThemePalette();
            }}
          >
            <ListItemIcon>
              {themePalette === "dark" ? <WbSunnyIcon /> : <Brightness3Icon />}
            </ListItemIcon>
            <ListItemText
              primary={
                themePalette === "dark"
                  ? "Enter bright mode"
                  : "Enter dark mode"
              }
            />
          </ListItem>
          {user.is_team_leader && (
            <ListItem button component={Link} to="/admin" onClick={handleAdminClick}>
              <ListItemIcon>
                <GradeIcon />
              </ListItemIcon>
              <ListItemText primary="Admin" />
            </ListItem>
          )}
          {user.is_staff && (
            <ListItem
              button
              onClick={() => {
                handleAdminRedirect();
              }}
            >
              <ListItemIcon>
                <GradeIcon />
              </ListItemIcon>
              <ListItemText primary="Admin" />
            </ListItem>
          )}
          <ListItem
            button
            onClick={() => {
              handleSignOut();
            }}
          >
            <ListItemIcon>
              <PowerSettingsNewIcon />
            </ListItemIcon>
            <ListItemText primary="Sign out" />
          </ListItem>
        </List>
      </Popover>
    </div>
  );

  return (
    location.pathname !== "/login" && (
      <div>
        <HideOnScroll>
          <AppBar color="inherit">
            {largeScreen ? horizontalNavigationBar : verticalNavigationBar}
          </AppBar>
        </HideOnScroll>
        <Drawer anchor="right" open={DrawerOpen} onClose={handleDrawerClose}>
          {verticalNavigationDrawer}
        </Drawer>
      </div>
    )
  );
};
