import React from "react";
import { useLocation } from "react-router-dom";
import styles from "./Page.module.scss";

export default ({ children }) => {
  const location = useLocation();
  const no_top_margin_paths =
    (location.pathname === "/login") |
    location.pathname.startsWith("/password-reset/confirm/");
  return (
    <div className={no_top_margin_paths ? "" : styles.pageContent}>
      {children}
    </div>
  );
};
