import React from "react";
import { UserContext } from "../contexts/UserContext";
import { Box, Divider, IconButton, Menu, MenuItem } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import SongActionDialog from "../routes/SongProposals/components/SongActionDialog";
import SongAssignEventDialog from "../routes/SongProposals/components/SongAssignEventDialog";
import SongUpdateDialog from "../routes/SongProposals/components/SongUpdateDialog";
import songsAPI from "../restApi/songsApi";
import eventsAPI from "../restApi/eventsApi";

export default ({ song, ...other }) => {
  const { event, actions, events } = { ...other };
  const userContext = React.useContext(UserContext);
  const [songActionDialog, setSongActionDialog] = React.useState({
    song: null,
    open: false,
    action: null,
  });
  
  const [openUpdateDialog, setOpenUpdateDialog] = React.useState({
    open: false,
    song: null,
  });
  const [assignEventDialog, setAssignEventDialog] = React.useState({
    open: false,
    song: null,
  });
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const handleOpenActionDialog = ({ song, action }) => {
    setSongActionDialog({ song: song, open: true, action: action });
  };

  const handleCloseActionDialog = (confirmedAction) => {
    if (confirmedAction) {
      // Send appropriate requests for the action
      if (songActionDialog.action === "add") {
        songsAPI.patch(songActionDialog.song.url, { known: true })
          .then((res) => {
            actions.add();
          })
      }
      if (songActionDialog.action === "delete") {
        songsAPI.delete(songActionDialog.song.url)
          .then((res) => {
            actions.delete(songActionDialog.song);
          })
      }
    }

    setSongActionDialog({ song: null, open: false, action: null });
  };

  const handleOpenUpdateDialog = (song) => {
    setOpenUpdateDialog({ open: true, song });
  };

  const handleOpenAssignEventDialog = (song) => {
    setAssignEventDialog({ open: true, song });
  };

  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog({ open: false, song: null });
  };

  const handleCloseAssignEventDialog = (selectedEvents) => {
    if (selectedEvents !== null) {
      eventsAPI.assignSong({
            song: song.id,
            events: selectedEvents.map((e) => e.id),
          })
        .then(() => {
          actions.assignEvent(song, selectedEvents);
        })
    }
    setAssignEventDialog({ open: false, song: null });
  };

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <Box component="span">
      <IconButton
        aria-label="Edit"
        title="Edit"
        aria-controls="songOptions"
        aria-haspopup="true"
        onClick={handleMenuClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="songOptions"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        {!!(userContext.user.is_team_leader | userContext.user.is_staff) &&
          "assignEvent" in actions && (
            <div>
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  handleOpenAssignEventDialog(song);
                }}
              >
                Assign to event
              </MenuItem>
              <Divider />
            </div>
          )}
        {!!(userContext.user.is_team_leader | userContext.user.is_staff) &&
          "add" in actions && (
            <div>
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  handleOpenActionDialog({ song: song, action: "add" });
                }}
              >
                Add to catalog
              </MenuItem>
              <Divider />
            </div>
          )}
        {"edit" in actions && (
          <div>
            <MenuItem
              onClick={() => {
                handleMenuClose();
                handleOpenUpdateDialog(song);
              }}
            >
              Edit
            </MenuItem>
            <Divider />
          </div>
        )}
        {!!(userContext.user.is_team_leader | userContext.user.is_staff) &&
          "unassignEvent" in actions && (
            <div>
              <MenuItem
                onClick={() => {
                  actions.unassignEvent(song, event);
                }}
              >
                Unassign
              </MenuItem>
              <Divider />
            </div>
          )}
        {"delete" in actions && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              handleOpenActionDialog({ song: song, action: "delete" });
            }}
          >
            Delete
          </MenuItem>
        )}
      </Menu>
      {songActionDialog.open && (
        <SongActionDialog
          song={songActionDialog.song}
          open={songActionDialog.open}
          action={songActionDialog.action}
          handleClose={handleCloseActionDialog}
        />
      )}
      {("add" in actions || "delete" in actions) && songActionDialog.open && (
        <SongActionDialog
          song={songActionDialog.song}
          open={songActionDialog.open}
          action={songActionDialog.action}
          handleClose={handleCloseActionDialog}
        />
      )}
      {"edit" in actions && openUpdateDialog.open && (
        <SongUpdateDialog
          open={openUpdateDialog.open}
          handleClose={handleCloseUpdateDialog}
          updateSong={actions.edit}
          song={openUpdateDialog.song}
        />
      )}
      {"assignEvent" in actions && assignEventDialog.open && (
        <SongAssignEventDialog
          song={assignEventDialog.song}
          events={events}
          open={assignEventDialog.open}
          user={userContext.user}
          handleClose={handleCloseAssignEventDialog}
        />
      )}
    </Box>
  );
};
