import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Popover } from "@material-ui/core";
import { Label } from "@material-ui/icons";
import BibleReferenceList from "./BibleReferenceList";

const useStyles = makeStyles((theme) => ({
  popoverBackground: {
    backgroundColor: theme.palette.background.default,
  },
}));

export default ({ song, className }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [verses, setVerses] = React.useState(song.bible_references || []);

  React.useEffect(() => {
    setVerses(song.bible_references || []);
  }, [song]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={className}>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        title="Bible references"
      >
        <Label />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        classes={{
          paper: classes.popoverBackground,
        }}
      >
        <BibleReferenceList song={song} verses={verses} setVerses={setVerses} />
      </Popover>
    </div>
  );
};
