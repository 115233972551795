import React from "react";
import styles from "./ExpantionPanelItem.module.scss";
import { UserContext } from "contexts/UserContext";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  IconButton,
  SvgIcon,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import SongMenuButton from "components/SongMenuButton";
import BibleReferenceButton from "components/BibleReference/BibleReferenceButton";
import YouTube from "react-youtube";
import songsAPI from "../../../restApi/songsApi";

const useStyles = makeStyles((theme) => {
  return {
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "50.0%",
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
      paddingLeft: "5px",
    },
    bodyContent: {
      whiteSpace: "pre-wrap",
      flexDirection: "column",
    },
    expandedPanelSummary: {
      backgroundColor: theme.palette.grey[700],
      position: "sticky",
      top: "-8px",
    },
    spotifyButton: {
      display: "block",
    },
    actionButtons: {
      float: "right",
      position: "sticky",
      top: theme.spacing(8),
    },
  };
});

export default ({ song, ...other }) => {
  const userContext = React.useContext(UserContext);
  const classes = useStyles();
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState(false);
  const [lyrics, setLyrics] = React.useState(null);
  const [youtube, setYoutube] = React.useState({ id: null, link: null });
  const [loading, setLoading] = React.useState(null);
  const smallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const videoStyling = smallScreen
    ? { width: "100%" }
    : { width: "100%", height: "170px" };

  const handleChange = (panel) => (event, isExpanded) => {
    if (!lyrics) {
      setLoading(true);
      songsAPI.getSong(song.id).then((res) => {
        setLoading(false);
        setLyrics(res.data.lyrics);
        setYoutube({ id: res.data.youtube_id, link: res.data.youtube_link });
      });
    }
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded === song.id}
      onChange={handleChange(song.id)}
      key={song.id}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls={song.id.toString + "bh-content"}
        id={song.id.toString + "bh-header"}
        classes={{
          expanded: classes.expandedPanelSummary,
        }}
        style={{ paddingLeft: "4%", paddingRight: "4%" }}
      >
        <Typography className={classes.heading}>{song.title}</Typography>
        <Typography className={classes.secondaryHeading}>
          {song.artist}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        className={classes.bodyContent}
        style={{ paddingLeft: "4%", paddingRight: "4%" }}
      >
        {loading && (
          <div className="text-center">
            <CircularProgress color="secondary" />
          </div>
        )}
        {youtube.id && (
          <YouTube
            videoId={youtube.id}
            className="video"
            containerClassName={styles.videoWrapper}
            opts={videoStyling}
          />
        )}
        <div>
          <span className={classes.actionButtons}>
            {!!(
              userContext.user.is_team_leader | userContext.user.is_staff
            ) && (
              <div style={{ display: "block" }}>
                <SongMenuButton
                  song={{
                    ...song,
                    youtube_link: song.youtube_link
                      ? song.youtube_link
                      : youtube.link,
                    lyrics: song.lyrics ? song.lyrics : lyrics,
                  }}
                  {...other}
                />
              </div>
            )}
            <BibleReferenceButton song={song} />
            {song.spotify_id && (
              <IconButton
                href={`spotify:track:${song.spotify_id}`}
                title="Open in Spotify"
                className={classes.spotifyButton}
              >
                <SvgIcon>
                  <path d="M19.098 10.638c-3.868-2.297-10.248-2.508-13.941-1.387-.593.18-1.22-.155-1.399-.748-.18-.593.154-1.22.748-1.4 4.239-1.287 11.285-1.038 15.738 1.605.533.317.708 1.005.392 1.538-.316.533-1.005.709-1.538.392zm-.126 3.403c-.272.44-.847.578-1.287.308-3.225-1.982-8.142-2.557-11.958-1.399-.494.15-1.017-.129-1.167-.623-.149-.495.13-1.016.624-1.167 4.358-1.322 9.776-.682 13.48 1.595.44.27.578.847.308 1.286zm-1.469 3.267c-.215.354-.676.465-1.028.249-2.818-1.722-6.365-2.111-10.542-1.157-.402.092-.803-.16-.895-.562-.092-.403.159-.804.562-.896 4.571-1.045 8.492-.595 11.655 1.338.353.215.464.676.248 1.028zm-5.503-17.308c-6.627 0-12 5.373-12 12 0 6.628 5.373 12 12 12 6.628 0 12-5.372 12-12 0-6.627-5.372-12-12-12z" />
                </SvgIcon>
              </IconButton>
            )}
          </span>
          <Typography>{song.lyrics ? song.lyrics : lyrics}</Typography>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
