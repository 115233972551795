import React from "react";
import Page from "./components/Page";
import Catalog from "./routes/Catalog";
import Calendar from "./routes/Calendar";
import { Login } from "./routes/Login";
import { PrivacyPolicy } from "./routes/PrivacyPolicy";
import { Signup } from "./routes/Signup";
import Admin from "./routes/Admin";
import AccountSettings from "./routes/AccountSettings"
import PasswordReset from "./routes/PasswordReset";
import Navbar from "./components/Navigationbar";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Box} from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import SongProposals from "./routes/SongProposals";
import { UserContext } from "./contexts/UserContext";
import { TOKEN_KEY, USER_KEY } from "./CONSTANTS";
import axios from "axios";

function App() {
  const [themePalette, setThemePalette] = React.useState("dark");
  const theme = createMuiTheme({
    palette: {
      type: themePalette,
    },
    overrides: {
      MuiOutlinedInput: {
        input: {
          "&:-webkit-autofill": {
            WebkitBoxShadow:
              themePalette === "dark" ? "0 0 0 100px #4a4e54 inset" : "#00ff00",
            WebkitTextFillColor: "#fff",
          },
        },
      },
    },
  });
  const storageUser = JSON.parse(localStorage.getItem("user"));
  const [config, setConfig] = React.useState(null);

  const [user, setUser] = React.useState(
    storageUser
      ? {
          isAuthenticated: storageUser.isAuthenticated,
          email: storageUser.email,
          first_name: storageUser.first_name,
          team: storageUser.team,
          is_team_leader: storageUser.is_team_leader,
          is_staff: storageUser.is_staff,
        }
      : {
          isAuthenticated: false,
          email: "",
          first_name: "",
          team: null,
          is_team_leader: false,
          is_staff: false,
        }
  );
  const userContextProviderValue = React.useMemo(() => ({ user, setUser }), [
    user,
    setUser,
  ]);

  React.useEffect(() => axios.get("/api/config/").then((res) => setConfig(res.data)), []);

  const PrivateRoute = ({ children, ...rest }) => {
    if (user.isAuthenticated) {
      return (
        <Box>
          <Navbar
            user={user}
            themePalette={themePalette}
            setThemePalette={setThemePalette}
          />
          <Route {...rest}>{children}</Route>
        </Box>
      );
    }
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
    setUser({
      isAuthenticated: false,
      email: "",
      first_name: "",
      team: null,
      is_team_leader: false,
      is_staff: false,
    });
    return <Redirect to="/login" />;
  };

  return (
    <Router basename="/">
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Page>
          <UserContext.Provider value={userContextProviderValue}>
            <Switch>
              <Route path="/privacy_policy" exact>
                <PrivacyPolicy />
              </Route>
              <Route path="/login" exact>
                <Login config={config} />
              </Route>
              <Route path="/signup">
                <Signup config={config} />
              </Route>
              <Route
                path="/password-reset/confirm/:id/:token"
                component={PasswordReset}
              />
              <PrivateRoute path="/catalog" component={Catalog} />
              <PrivateRoute
                path="/song-proposals"
                exact
                component={SongProposals}
              />
              <PrivateRoute path="/calendar" exact component={Calendar} />
              <PrivateRoute path="/admin" exact component={Admin} />
              <PrivateRoute path="/account" exact component={AccountSettings} />
              <PrivateRoute path="/" component={Calendar} />
            </Switch>
          </UserContext.Provider>
        </Page>
      </MuiThemeProvider>
    </Router>
  );
}

export default App;
